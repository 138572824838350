import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { Dayjs } from 'dayjs'
import { DateRange } from '@mui/x-date-pickers-pro'
import { PaymentMethod } from '@stripe/stripe-js'
import { INIT_UUID } from 'consts'

export interface braintreePaymentInfo {
    braintreeCustomerId: string
    braintreePaymentMethodId: string
    braintreeTransactionId: string
    brand: string
    last4: string
    expiration: string
}
export interface CheckoutTransactionDetailsParams {
    // Stripe
    stripePaymentIntentId: string
    stripePaymentMethodId: string
    stripeCustomerId: string

    // Braintree
    braintreeTransactionId: string
    braintreePaymentMethodId: string
    braintreeCustomerId: string
    shouldVaultPayment: boolean
    usage: 'MULTI_USE' | 'SINGLE_USE'
}

export type CheckoutInsuranceDetailsParams = {
    braintreePaymentNonce: string | null
    benefitDetailsAmountsBasePremiumValue: string
    benefitCode: string
    cancelCoverage: boolean
    coveredAmount: number
    insuredAll: boolean
    productDetailsAmountsTotalBasePremium: string
    productDetailsAmountsTotalPremiumValue: string
    productDetailsAmountsTotalStandardPremium: string
    travelerDateOfBirth: string
}

export interface CheckoutParams {
    billingInfo: CheckoutBillingInfoParams
    cartId: string
    email: string
    layaway: boolean
    members: CheckoutMemberParams[]
    roomInfo: CheckoutRoomInfoParams[]
    shippingInfo: CheckoutShippingInfoParams
    transactionDetails: CheckoutTransactionDetailsParams
    userId: string
    insuranceDetails: CheckoutInsuranceDetailsParams | null
}
export interface CheckoutBillingInfoParams extends BillingInfo {}
export interface CheckoutShippingInfoParams extends ShippingInfo {}
export interface CheckoutRoomInfoParams extends RoomInfo {}

export interface CheckoutMemberParams {
    email: string
    firstName: string
    lastName: string
    primary: boolean
    total: number
}

export interface MemberPlan {
    id: string
    date: Date
    charged: number
    isFirstPayment: boolean
    transactionStatus: string
    shouldChargeNow: boolean
}

export interface PaymentArray {
    Amount: number
    Created: Date
    Status: string
    Brand: string
    last4: string
    Type: string
}

export interface ProcessedMemberInfo extends MemberInfo {
    memberName: string
    processedEmail: string
    isYourself: boolean
    displayName: string
}

export interface OrderPaymentDetail {
    isLastInstallment: boolean
    installmentID: string
    subtotal: number
    total: number
    paidAmount: number
    cardDetail: {
        brand: string
        last4: string
    }
}

export interface PayModalInfo {
    orderPaymentDetail: OrderPaymentDetail
    previousSelectedMemberID: string
    showCompletePaymentModal: boolean
    showMemberPaymentDetail: boolean
    showPayForAFriendOrYourselfModal: boolean
    showPaymentSuccessModal: boolean
    showSelectPaymentOptionModal: boolean
    selectedMemberID: string
    selectedPayInstallmentID: string
    selectPayInFull: boolean
    selectLayawayPlans: boolean
}

export interface PopUpStatus {
    buttonMessage: string
    headMessage: string
    show: boolean
    tipsMessage: string
    tipsUrl: string
}

export interface AddToCartInfo {
    addOnItems?: AddToCartItem[]
    addToCartCount: number
    checkInDate: string | Date
    checkOutDate: string | Date
    code?: string
    comparedEventsID: string
    comparedEventsIDInPreview: string
    eventID?: string
    eventSlug?: string
    firstCheckInDate: any
    firstCheckOutDate: any
    firstSelectedCheckInDate: DateRange<Dayjs> | [null, null]
    groupSize?: number
    hasBeenOpenedRoomPage: boolean
    hasBeenToHotelPageEventID: string
    hasClickNavbar: boolean
    hasSelectedHotelID: string
    hotelID?: string
    isBundle?: boolean
    isCodeValid?: boolean
    isPreSale?: boolean
    isShoulderNights: boolean
    isShowShoppingBag?: boolean
    presaleCode?: string
    previewEventSlug?: string
    previousHotelID?: string
    previousSelectedTicketNumber?: string
    previousTicketItems?: AddToCartItem[]
    roomItems?: AddToCartItem[]
    roomItemsPrice?: AddToCartItem[]
    roomTierItems?: AddToCartItem[]
    selectedTicketNumber?: string
    shoulderNights: DateRange<Dayjs> | undefined
    shoulderNightsItem?: AddToCartItem[]
    shouldShowCountdown: boolean
    stayOnAddOnsPage?: boolean
    ticketExperienceItems?: AddToCartItem[]
    ticketItems?: AddToCartItem[]
}

export interface AddToCartItem {
    itemID: string
    price: number
    quantity: number
    type: string
    pricePerRoom?: boolean
}

// ShoppingCart
export interface ShoppingCartState {
    currency: string
    depositAmount: number
    discount: null | ShoppingCartDiscount
    eventID: string
    expires: string | Date
    expiresResetsLeft: number
    expiresResetsLeftForDeclinedPayment: number
    groupSize: number
    id: string
    items: ShoppingCartItem[]
    layawayFee: number // event layawayFee
    message?: string
    meta: Record<string, string>
    presaleCode: string
    purchaseAddOnsMemberId: string
    totalPorterageFee: number
    totalResortFee: number
    totals: {
        full: number
        layaway: number
        occupancyFee: number
        subtotal: number
        layawayFee: number
        taxes: number
        discount?: number
    }
    transactionDetails: TransactionDetails
}

export interface ShoppingCartDiscount {
    amount: number
    amountType: string
    code: string
    codeId: string
    discountPrice: number
    isIncludeTaxesAndFees: boolean
}

export interface CardDetails {
    brand: string
    expMonth: number
    expYear: number
    last4: string
}

export interface TransactionDetails {
    cardDetails: CardDetails
    // Braintree
    braintreeCustomerId: string
    braintreePaymentMethodId: string
    braintreeTransactionId: string

    paymentProcessorName: 'stripe' | 'braintree' | ''

    // Stripe
    stripeClientSecretId: string
    stripeCustomerId: string
    stripePaymentIntentId: string
    stripePaymentMethodId: string
}

export interface ShoppingCartItem {
    baseQuantity: number
    itemID: string
    name?: string
    overBaseQuantityFee: number
    parent: string
    price: number
    pricePerPerson: boolean
    pricePerRoom: boolean
    quantity: number
    required: boolean
    shoppingCartID: string
    taxBreakdown: Record<string, number>[]
    taxesAndFees: number
    type:
        | 'item'
        | 'room'
        | 'room.shouldernight'
        | 'room.tier'
        | 'room.tier.price'
        | 'ticket'
        | 'ticket.experience'
        | 'ticket.experience.tier'
        | 'ticket.tier'
}

// add shopping cart params, user for create cart or update cart
export interface CartParams {
    eventID: string
    groupSize: number
    id: string
    items: CartItemParams[]
    meta: Record<string, string>
    presaleCode: string
    purchaseAddOnsMemberId: string
    hotelId?: string
}

export interface CartItemParams {
    itemID: string
    price: number
    quantity: number
    type:
        | 'item'
        | 'room.tier.price'
        | 'room.shouldernight'
        | 'ticket.experience.tier'
        | 'ticket.tier'
}

export interface RoomInfo {
    email: string
    firstName: string
    lastName: string
    roomID: string
}

export interface BillingInfo {
    address: string
    address2: string
    city: string
    country: string
    firstName: string
    lastName: string
    phone: string
    phoneAuthorization: string
    state: string
    zip: string
}

export interface ShippingInfo extends BillingInfo {}

export type InsuranceDetailsObject = {
    travelGuard: TravelGuardDetails[]
}

export type TravelGuardDetails = {
    bookingEndDate: string | Date
    bookingStartDate: string | Date
    coverageChosen: string
    coveredAmount: string
    email: string
    insuranceCost: string
    insuredAll: boolean
    orderMemberId: string
    purchaseDateTime: string
    travelerIdentifier: string
    tripIdentifier: string
    userId: string
}

export interface OrderState {
    billingInfo: BillingInfo | null
    cancelAt: Date | string
    created: Date | string
    currency: string
    displayStatus: string
    discount: ShoppingCartDiscount
    details: string
    email: string
    error: string | null
    eventLayawayFee: number
    eventID: string
    groupSize: number
    id: string
    initialChargeAmount: number
    insuranceDetails: InsuranceDetailsObject | null
    items: OrderItem[]
    layawayFee: number
    lineItems: LineItem[]
    members: MemberInfo[]
    message?: string
    number: number
    purchaseTimeEventDepositAmount: number
    purchaseTimeEventDepositIsPerPerson: boolean
    purchaseTimeEventDepositIsPercentage: boolean
    roomInfo: RoomInfo[]
    shippingInfo: ShippingInfo | null
    stripeCustomerID: string
    stripePaymentIntentID: string
    stripePaymentMethod: any
    stripePaymentMethodID: string
    status: string
    taxesAndFees: number
    transactionDetails: TransactionDetails
    total: number
    totalPorterageFee: number
    totalResortFee: number
    userID: string
}

export interface LineItem {
    description: string
    id: string
    orderID: string
    price: number
    quantity: number
    taxesAndFees: number
}

export interface MemberInfo {
    Accepted: boolean
    BillingInfo: (BillingInfo & { addressOf?: string }) | null
    Email: string
    DepositAmount: number
    DisplayStatus: string
    FirstName: string
    ID: string
    LastName: string
    LayawayFee: number
    OrderID: string
    Position: number
    Primary: boolean
    ShippingInfo: (ShippingInfo & { addressOf?: string }) | null
    Status: string
    StripeCustomerID: string
    StripePaymentMethodID: string
    StripePaymentIntentID: string
    StripePaymentMethod: PaymentMethod
    Total: number
    TransactionDetails: TransactionDetails
    UserID: string
}

export enum ItemOrigin {
    CHECKOUT = 'checkout',
    ORDER_DETAIL = 'order_detail'
}

export interface OrderItem {
    baseQuantity: number
    itemID: string
    name: string
    orderID: string
    origin: ItemOrigin.CHECKOUT | ItemOrigin.ORDER_DETAIL
    overBaseQuantityFee: number
    parent: string
    price: number
    pricePerPerson: boolean
    quantity: number
    required: boolean
    taxesAndFees: number
    type: string
}

export interface CartPurchase {
    currency: string
    items: CartPurchaseItem[]
    tax?: number
    transaction_id?: string
    value: number
}

export interface ViewEvent {
    currency: string
    items: ViewEventItem[]
    value: number
}

export interface ViewEventItem {
    currency: string
    item_id: string
    item_name: string
    price: number
    quantity: number
    type: string
}

export interface CartPurchaseItem {
    currency: string
    item_id: string
    item_name: string
    price: number
    quantity: number
    type: string
}

export interface NavbarState {
    expandedNav: boolean
    forgotPassword: boolean
    menu: boolean
    resetPassword: boolean
    signIn: boolean
    signUp: boolean
    signUpSuccessEmail: string
}

export interface PaymentIntent {
    stripeClientSecret: string
    stripeCustomerID: string
    stripePaymentIntentID: string
}

export interface PageLoadingState {
    loading: boolean
}

export interface CanceledNavbarState {
    NavbarState: boolean
}

export interface OrderDetailState {
    DetailState: boolean
}

export interface SplitOrderState {
    order: OrderState | undefined
    paymentIntent: PaymentIntent | undefined
    transactionDetails: TransactionDetails | undefined
}

export interface CountDownState {
    isExpired: boolean
    isStop: boolean
}

export const initialTransactionDetails: TransactionDetails = {
    paymentProcessorName: '',
    stripePaymentIntentId: '',
    stripePaymentMethodId: '',
    stripeCustomerId: '',
    stripeClientSecretId: '',

    braintreeTransactionId: '',
    braintreePaymentMethodId: '',
    braintreeCustomerId: '',
    cardDetails: {
        brand: '',
        last4: '',
        expMonth: 0,
        expYear: 0
    }
}

export const initialCartState: ShoppingCartState = {
    currency: 'USD',
    depositAmount: 0,
    discount: null,
    eventID:
        typeof window !== 'undefined' && window.localStorage.getItem('eventID') !== 'undefined'
            ? window.localStorage.getItem('eventID') || INIT_UUID
            : INIT_UUID,
    expires: '',
    expiresResetsLeft: 0,
    expiresResetsLeftForDeclinedPayment: 0,
    groupSize: 0,
    id:
        typeof window !== 'undefined' &&
        window.localStorage.getItem('shoppingCartID') !== undefined &&
        window.localStorage.getItem('shoppingCartID')
            ? window.localStorage.getItem('shoppingCartID') || uuid()
            : uuid(),
    items: [],
    layawayFee: 0,
    meta: {},
    presaleCode: '',
    purchaseAddOnsMemberId: INIT_UUID,
    totalPorterageFee: 0,
    totalResortFee: 0,
    totals: {
        discount: 0,
        full: 0,
        layaway: 0,
        occupancyFee: 0,
        subtotal: 0,
        layawayFee: 0,
        taxes: 0
    },
    transactionDetails: initialTransactionDetails
}

interface OrdersState {
    error: string | null
    inventory: Record<string, number>
    inventoryError: any
    loading: boolean
    loadingInventory: boolean
    orders: OrderState[]
}
const initialOrdersState: OrdersState = {
    error: null,
    inventory: {},
    inventoryError: undefined,
    loading: false,
    loadingInventory: true,
    orders: []
}

const initialOrderState: OrderState = {
    billingInfo: null,
    cancelAt: '',
    created: '',
    currency: 'USD',
    discount: {
        amount: 0,
        amountType: 'percentage',
        codeId: '',
        code: '',
        discountPrice: 0,
        isIncludeTaxesAndFees: false
    },
    displayStatus: '',
    details: '',
    email: '',
    error: '',
    eventID: '',
    eventLayawayFee: 0,
    groupSize: 0,
    id: '',
    initialChargeAmount: 0,
    insuranceDetails: null,
    items: [],
    layawayFee: 0,
    lineItems: [],
    members: [],
    number: 0,
    purchaseTimeEventDepositAmount: 0,
    purchaseTimeEventDepositIsPerPerson: false,
    purchaseTimeEventDepositIsPercentage: false,
    roomInfo: [],
    shippingInfo: null,
    status: '',
    stripePaymentIntentID: '',
    stripePaymentMethod: null,
    stripeCustomerID: '',
    stripePaymentMethodID: '',
    taxesAndFees: 0,
    total: 0,
    totalPorterageFee: 0,
    totalResortFee: 0,
    transactionDetails: initialTransactionDetails,
    userID: ''
}

const initialPaymentIntentState: PaymentIntent = {
    stripeClientSecret: '',
    stripeCustomerID: '',
    stripePaymentIntentID: ''
}

const initialNavbarState: NavbarState = {
    expandedNav: false,
    forgotPassword: false,
    menu: false,
    resetPassword: false,
    signIn: false,
    signUp: false,
    signUpSuccessEmail: ''
}

const initialPageLoadingState: PageLoadingState = {
    loading: false
}

const initialCanceledNavbarState: CanceledNavbarState = {
    NavbarState: false
}

const initialOrderDetailState: OrderDetailState = {
    DetailState: false
}

const initialCountDownState: CountDownState = {
    isExpired: false,
    isStop: false
}

type SET_CART_ACTION = {
    type: 'shoppingCart/setCart'
    payload: ShoppingCartState
}

type REFRESH_CART_ID = {
    type: 'shoppingCart/refreshCartID'
    payload: string
}

type REFRESH_EXPIRES = {
    type: 'shoppingCart/refreshExpires'
    payload: string
}

type SET_CARTID = {
    type: 'shoppingCart/setCartID'
    payload: string
}

type SET_EXPIRES_RESET_LEFT = {
    type: 'shoppingCart/setExpiresResetLeft'
    payload: number
}

type LOAD_ORDER_START = {
    type: 'order/load/start'
}

type LOAD_ORDER_SUCCESS = {
    type: 'order/load/success'
    payload: OrderState
}

type LOAD_ORDER_ERROR = {
    type: 'order/load/error'
    payload: any
}

type LOAD_ORDERS_START = {
    type: 'orders/load/start'
}

type LOAD_ORDERS_ERROR = {
    type: 'orders/load/error'
    payload: string
}

type LOAD_ORDERS_SUCCESS = {
    type: 'orders/load/success'
    payload: OrderState[]
}

type ORDERS_ClEAR = {
    type: 'orders/clear'
    payload: OrderState[]
}

type LOAD_INVENTORY_SUCCESS = {
    type: 'orders/loadInventory/success'
    payload: Record<string, number>
}

type LOAD_INVENTORY_START = {
    type: 'orders/loadInventory/start'
}
type LOAD_INVENTORY_ERROR = {
    type: 'orders/loadInventory/error'
    payload: any
}

type SET_NAVBAR_STATE = {
    type: 'navbar/setState'
    payload: NavbarState
}

type SET_PAYMENT_INTENT_STATE = {
    type: 'paymentIntent/setState'
    payload: PaymentIntent
}

type UPDATE_NAVBAR_STATE = {
    type: 'navbar/updateState'
    payload: NavbarState
}

type PAGE_LOADING_STATE = {
    type: 'pageLoading/setState'
    payload: PageLoadingState
}

type CANCELED_NAVBAR_STATE = {
    type: 'canceled/setState'
    payload: CanceledNavbarState
}

type ORDER_NAVBAR_STATE = {
    type: 'orderDetail/setState'
    payload: OrderDetailState
}

type COUNT_DOWN_START = {
    type: 'countDown/start'
}

type COUNT_DOWN_STOP = {
    type: 'countDown/stop'
}

type COUNT_DOWN_EXPIRED = {
    type: 'countDown/expired'
}

type COUNT_DOWN_CANCEL_EXPIRED = {
    type: 'countDown/cancelExpired'
}

type ACTION =
    | SET_CART_ACTION
    | REFRESH_CART_ID
    | REFRESH_EXPIRES
    | LOAD_ORDERS_START
    | LOAD_ORDERS_ERROR
    | LOAD_ORDERS_SUCCESS
    | ORDERS_ClEAR
    | LOAD_INVENTORY_START
    | LOAD_INVENTORY_SUCCESS
    | LOAD_INVENTORY_ERROR
    | SET_NAVBAR_STATE
    | SET_PAYMENT_INTENT_STATE
    | UPDATE_NAVBAR_STATE
    | PAGE_LOADING_STATE
    | LOAD_ORDER_START
    | LOAD_ORDER_SUCCESS
    | LOAD_ORDER_ERROR
    | CANCELED_NAVBAR_STATE
    | ORDER_NAVBAR_STATE
    | SET_CARTID
    | SET_EXPIRES_RESET_LEFT
    | COUNT_DOWN_START
    | COUNT_DOWN_STOP
    | COUNT_DOWN_EXPIRED
    | COUNT_DOWN_CANCEL_EXPIRED

function shoppingCartReducer(state: ShoppingCartState = initialCartState, action: ACTION) {
    switch (action.type) {
        case 'shoppingCart/setCart':
            if ('message' in action.payload) {
                return state
            }
            if (action.payload.id && typeof window !== 'undefined') {
                if (action.payload.id == undefined || action.payload.id == null) {
                    console.log(
                        'shoppingCart/setCart called with action.payload.id undefined or null'
                    )
                }
                window.localStorage.setItem('shoppingCartID', action.payload.id)
                if (action.payload.eventID) {
                    window.localStorage.setItem('eventID', action.payload.eventID)
                }
                window.localStorage.setItem('cart', JSON.stringify(action.payload))
            }

            return action.payload

        case 'shoppingCart/refreshCartID':
            const newState = {
                ...state,
                id: action.payload,
                eventID: INIT_UUID,
                stripeClientSecret: '',
                currency: 'USD',
                depositAmount: 0,
                layawayFee: 0,
                items: [],
                meta: {},
                groupSize: 0,
                totals: {
                    full: 0,
                    layaway: 0,
                    layawayFee: 0,
                    occupancyFee: 0,
                    subtotal: 0,
                    taxes: 0
                },
                expires: '',
                expiresResetsLeft: 2,
                expiresResetsLeftForDeclinedPayment: 1
            }
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('shoppingCartID', newState.id)
                window.localStorage.setItem('eventID', newState.eventID)
                window.localStorage.setItem('cart', JSON.stringify(newState))
            }
            // console.log({action})
            // if (typeof window !== 'undefined') {
            //   window.localStorage.setItem('shoppingCartID', action.payload)
            // }
            // const newState = {...state};
            // newState.id = action.payload;
            // newState.items = [];
            // newState.expires = '';
            return newState
        case 'shoppingCart/setCartID':
            const cartState = { ...state, id: action.payload }
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('cart', JSON.stringify(cartState))
                window.localStorage.setItem('shoppingCartID', cartState.id)
            }
            return cartState

        case 'shoppingCart/refreshExpires':
            const refreshState = { ...state, expires: action.payload }
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('cart', JSON.stringify(refreshState))
            }
            return refreshState
        case 'shoppingCart/setExpiresResetLeft':
            const resetLeftState = { ...state, expiresResetsLeft: action.payload }
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('cart', JSON.stringify(resetLeftState))
            }
            return resetLeftState
        default:
            return state
    }
}

function orderReducer(state: OrderState = initialOrderState, action: ACTION) {
    switch (action.type) {
        case 'order/load/start':
            return { ...state, loading: true }
        case 'order/load/success':
            if (action.payload.id && typeof window !== 'undefined') {
                window.localStorage.setItem('orderId', action.payload.id)
                if (action.payload.eventID) {
                    window.localStorage.setItem('eventID', action.payload.eventID)
                }
            }
            return { ...state, loading: false, ...action.payload }
        case 'order/load/error':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

function paymentIntentReducer(state: PaymentIntent = initialPaymentIntentState, action: ACTION) {
    switch (action.type) {
        case 'paymentIntent/setState':
            return action.payload
        default:
            return state
    }
}

function ordersReducer(state: OrdersState = initialOrdersState, action: ACTION): OrdersState {
    switch (action.type) {
        case 'orders/loadInventory/start':
            return { ...state, loadingInventory: true }
        case 'orders/loadInventory/error':
            return { ...state, loadingInventory: false, inventoryError: action.payload }
        case 'orders/loadInventory/success':
            return { ...state, inventory: action.payload, loadingInventory: false }
        case 'orders/load/start':
            return { ...state, loading: true }
        case 'orders/load/error':
            return { ...state, loading: false, error: action.payload }
        case 'orders/load/success':
            return { ...state, loading: false, orders: [...state.orders, ...action.payload] }
        case 'orders/clear':
            return { ...state, loading: false, orders: [] }
        default:
            return state
    }
}

function navbarReducer(state: NavbarState = initialNavbarState, action: ACTION) {
    switch (action.type) {
        case 'navbar/setState':
            return { ...initialNavbarState, ...action.payload }
        case 'navbar/updateState':
            return { ...state, ...action.payload }
        default:
            return state
    }
}

function pageLoadingReducer(state: PageLoadingState = initialPageLoadingState, action: ACTION) {
    switch (action.type) {
        case 'pageLoading/setState':
            return { ...initialNavbarState, ...action.payload }
        default:
            return state
    }
}

function CanceledNavbarReducer(
    state: CanceledNavbarState = initialCanceledNavbarState,
    action: ACTION
) {
    switch (action.type) {
        case 'canceled/setState':
            return { ...initialCanceledNavbarState, ...action.payload }
        default:
            return state
    }
}

function OrderDetailReducer(state: OrderDetailState = initialOrderDetailState, action: ACTION) {
    switch (action.type) {
        case 'orderDetail/setState':
            return { ...initialOrderDetailState, ...action.payload }
        default:
            return state
    }
}

function CountDownReducer(state = initialCountDownState, action: ACTION) {
    switch (action.type) {
        case 'countDown/stop':
            return { ...initialCountDownState, isStop: true }
        case 'countDown/start':
            return { ...initialCountDownState, isStop: false }
        case 'countDown/expired':
            return { ...initialCountDownState, isExpired: true }
        case 'countDown/cancelExpired':
            return { ...initialCountDownState, isExpired: false }
        default:
            return state
    }
}

const store = configureStore({
    reducer: combineReducers({
        shoppingCartReducer,
        orderReducer,
        ordersReducer,
        navbarReducer,
        pageLoadingReducer,
        paymentIntentReducer,
        CanceledNavbarReducer,
        OrderDetailReducer,
        CountDownReducer
    })
})
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
