import { HotelJSON } from '../../../../events-service/src/models/hotel'
import Slider from 'react-slick'
import Image from '../image'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'
import {
    COLOR_GRAY_500,
    COLOR_PRIMARY_600,
    COLOR_WHITE,
    FONT_SIZE,
    FONT_SIZE_32,
    FULL_WIDTH,
    HALF_WIDTH,
    SIZE_2,
    SIZE_20,
    SIZE_36
} from 'styles/theme'
import { Skeleton } from '@mui/material'
import { formatMoney, handleAmountsPoint } from 'util/parse'
import { ButtonComponent } from 'components/Button'
import clsx from 'clsx'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useStyles } from './hotelCard.styles'
import LockIcon from '@mui/icons-material/Lock'
import { cardButtonStatus } from 'enums'
import { HotelStatusType } from 'hooks/useHotelStatus'

const slickSettings = {
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'ease',
    dots: true,
    nextArrow: (
        <span aria-hidden="true">
            <NavigateNextIcon
                sx={{
                    backgroundColor: COLOR_WHITE,
                    borderRadius: HALF_WIDTH,
                    color: COLOR_GRAY_500,
                    fontSize: SIZE_36,
                    left: HALF_WIDTH,
                    position: 'absolute',
                    top: HALF_WIDTH,
                    transform: 'translate(-50%,-50%)'
                }}
            />
        </span>
    ),
    prevArrow: (
        <span aria-hidden="true">
            <NavigateNextIcon
                sx={{
                    backgroundColor: COLOR_WHITE,
                    borderRadius: HALF_WIDTH,
                    color: COLOR_GRAY_500,
                    fontSize: SIZE_36,
                    left: HALF_WIDTH,
                    position: 'absolute',
                    top: HALF_WIDTH,
                    transform: 'translate(-50%,-50%) rotate(180deg)'
                }}
            />
        </span>
    )
}

export type HotelCardType = {
    currency: string
    dateDisplay?: string
    groupSize?: number
    hasShoulderNights?: boolean
    hotel: HotelJSON
    hotelIndex?: number
    hotelShoulderNights: DateRange<Dayjs> | undefined
    hotelStatus: HotelStatusType
    isCodeValid: boolean
    isLoading?: boolean
    isMobileWidth?: boolean
    isOnlySellHotel: boolean
    isPreSale: boolean
    onHandleImageClick: (hotelImageUrl: any[]) => void
    onHotelCardClick: (
        hotelId: string,
        onButton: boolean,
        shoulderNights: DateRange<Dayjs> | undefined
    ) => void
    onUnlockButtonClick: () => void
    submitting: boolean
}

export const HotelCard = ({
    currency,
    dateDisplay,
    groupSize = 2,
    hasShoulderNights,
    hotel,
    hotelIndex = 0,
    hotelShoulderNights,
    hotelStatus,
    isCodeValid,
    isLoading = false,
    isMobileWidth = false,
    isOnlySellHotel,
    isPreSale,
    onHandleImageClick,
    onHotelCardClick,
    onUnlockButtonClick,
    submitting
}: HotelCardType) => {
    const { classes } = useStyles()
    const { buttonStatus, price, saleStatusOnHotelCard, shouldShowButton, shouldShowPrice } =
        hotelStatus
    const isFuture = saleStatusOnHotelCard.includes('On Sale')
    const onSale = buttonStatus === cardButtonStatus.SELECT_HOTEL
    const { description, headerImage, id, name } = hotel

    const renderSelectHotelButton = () => {
        if (!shouldShowButton) return null
        let onButtonClick = (event: any) => {}
        let context: string | JSX.Element = buttonStatus
        let isDisabledButton: boolean = false
        let isPresaleButton: boolean = false
        let isCursorPointer: boolean = false
        switch (buttonStatus) {
            case cardButtonStatus.UNLOCK:
                isPresaleButton = true
                context = (
                    <>
                        <span className={classes.unlockIcon}>
                            <LockIcon />
                        </span>
                        Unlock
                    </>
                )
                onButtonClick = onUnlockButtonClick
                break
            case cardButtonStatus.SELECT_HOTEL:
                isCursorPointer = true
                onButtonClick = (event) => {
                    event.stopPropagation()
                    onHotelCardClick(id, true, hotelShoulderNights)
                }
                break

            default:
                isDisabledButton = true
                break
        }
        return (
            <div>
                <ButtonComponent
                    circularProgressSize={16}
                    className={clsx({
                        [classes.button]: true,
                        [classes.presaleButton]: isPresaleButton,
                        [classes.pointer]: isCursorPointer
                    })}
                    disabled={isDisabledButton}
                    isLoading={submitting}
                    onClick={onButtonClick}
                    title={context}
                />
            </div>
        )
    }
    const onImageClick = (event: any) => {
        event.stopPropagation()
        onHandleImageClick(headerImage)
    }

    const onCardClick = (event: any) => {
        if (!onSale) return
        event.stopPropagation()
        onHotelCardClick(id, false, hotelShoulderNights)
    }

    const renderHotelPrice = (status: string) => {
        if (status === cardButtonStatus.SOLD_OUT || status === cardButtonStatus.UNAVAILABLE)
            return ''
        return (
            <>
                <div className={classes.pricePerPerson}>
                    <span className={classes.priceText}>
                        From {handleAmountsPoint(formatMoney(price, currency))}
                    </span>{' '}
                    / Person
                </div>
                <div className={classes.totalPrice}>
                    {handleAmountsPoint(formatMoney(price * groupSize, currency))} Total (Taxes and
                    fees included)
                    {isOnlySellHotel && <p>*Price shown based on 2 people</p>}
                </div>

                {isFuture && !isCodeValid && (
                    <div
                        style={{
                            paddingTop: SIZE_20
                        }}
                    >
                        {status}
                    </div>
                )}
            </>
        )
    }
    return (
        <div
            className={clsx({
                [classes.onlySellHotelWrapper]: isOnlySellHotel,
                [classes.wrapper]: !isOnlySellHotel
            })}
            style={{ marginBottom: isOnlySellHotel ? FONT_SIZE : FONT_SIZE_32 }}
        >
            <div className={classes.hotelItemWrapper}>
                <div className={classes.hotelItemLink}>
                    {isLoading ? (
                        <Skeleton
                            animation="wave"
                            key={`hotelImageSkeleton_${hotelIndex}`}
                            sx={{
                                maskImage: 'none',
                                paddingBottom: '64%'
                            }}
                            variant="rectangular"
                        />
                    ) : (
                        <div
                            data-bs-ride="carousel"
                            style={{
                                position: 'relative'
                            }}
                        >
                            <div className={classes.imageWrapper}>
                                <Slider {...slickSettings}>
                                    {headerImage.map((imageItem: any, index: number) => {
                                        return (
                                            <div
                                                className={classes.imageContainer}
                                                key={'hotel-image-' + index}
                                            >
                                                <Image
                                                    alt=""
                                                    layout="fill"
                                                    onClick={onImageClick}
                                                    sizes="(max-width: 384px) 100vw, (max-width: 768) 50vw, 33vw"
                                                    src={imageItem.url}
                                                    style={{
                                                        width: FULL_WIDTH
                                                    }}
                                                />
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                    )}
                    {isLoading ? (
                        <Skeleton
                            animation="wave"
                            key={`hotelDetailSkeleton_${hotelIndex}`}
                            sx={{
                                height: '400px',
                                maskImage: 'none'
                            }}
                            variant="rectangular"
                        />
                    ) : (
                        <div
                            className={classes.infoWrapper}
                            style={{
                                cursor: onSale || isFuture ? 'pointer' : 'not-allowed'
                            }}
                            onClick={onCardClick}
                        >
                            <div className={classes.name}>{name}</div>
                            <div
                                className={classes.dateDisplay}
                                style={{
                                    color: isMobileWidth ? COLOR_PRIMARY_600 : ''
                                }}
                            >
                                {dateDisplay}
                                {hasShoulderNights && ' (Additional Nights Available)'}
                            </div>
                            <div className={classes.description}>
                                <div dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                            <div
                                className={clsx({
                                    [classes.roomPropertyWrapper]: true,
                                    [classes.presaleButtonWrapper]: isPreSale
                                })}
                            >
                                {shouldShowPrice && (
                                    <div
                                        style={{
                                            paddingRight: SIZE_2
                                        }}
                                    >
                                        {renderHotelPrice(saleStatusOnHotelCard)}
                                    </div>
                                )}
                                {renderSelectHotelButton()}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
