import {
    AddToCartItem,
    CartItemParams,
    CartParams,
    MemberInfo,
    OrderState,
    PopUpStatus,
    ShoppingCartState
} from 'store'
import { EventJSON } from '../../../events-service/src/models/event'
import { SelectAddOnType } from 'util/customizeJsonType'
import { useContext, useEffect, useState } from 'react'
import { CheckAnyMembersPurchasing, fetchCart, upsertCart } from 'store/actions/fetchCart'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
    APP_NAME,
    GAProductType,
    GoogleAnalyticsEventActionType,
    googleAnalyticsEvent,
    handleOrder
} from 'components/Google/GoogleAnalytics'
import { AuthContext } from '../auth/context'
import {
    AdditionalAddOnType,
    AddOnOrderQuantityType,
    CartItemWithCategoryType
} from 'types/checkout'
import { formatMoney, getRoomQty, handleAmountsPoint } from 'util/parse'
import { get, getInventory } from 'store/actions/fetchOrders'
import router from 'next/router'
import { AddOnItemType } from '../../../events-service/src/models/addOnItem'
import { useLocalStorage } from './useLocalStorage'
import getConfig from 'next/config'
import { eventsWithoutJwtClient } from 'apollo-client'
import { GET_ORDER_ID_QUERY } from 'graphQL/order/getOrderID.graphql'
import { isEqual } from 'lodash'
import { calculateInventoryIncludeCard } from 'util/event'
import { reFreshShoppingCart } from 'util/cart'
import {
    ADDON_REACHES_THE_upper_LIMIT,
    INIT_UUID,
    INVENTORY_SHORTAGE_MESSAGE,
    ORDER_STATUS_IS_CANCELED
} from 'consts'
import { OrderStatus } from 'enums/orderStatus'

type AdditionalAddOnsProps = {
    orderId: string
}

type AdditionalAddOnsReturnType = {
    addOnCartState: {
        getGroupLimitAvailableQuantity: () => number
        handleAddItemOnClick: (
            id: string,
            addOnItem: (AddOnItemType & AddOnOrderQuantityType) | undefined,
            CategoryAddOnsItemArray: (AddOnItemType & AddOnOrderQuantityType)[] | undefined
        ) => void
        handleRemoveCart: (id: string, haveCategory: boolean) => void
        isOtherMemberAddCart: boolean
        notCategoryNotPerson: (id: string) => void
        selectAddOnsItem: Record<string, CartItemWithCategoryType>
    }
    addOnInventoryErrorItem:
        | {
              errorItem: AddOnItemType[]
              onRefreshClick: () => void
              show: boolean
          }
        | undefined
    addOnLimitModalState: {
        closeAddOnLimitModal: () => void
        maxLimitationAddOn:
            | {
                  names: string[]
                  show: boolean
              }
            | undefined
    }
    addOnsItemArray: (AddOnItemType & AddOnOrderQuantityType)[]
    cart: ShoppingCartState
    confirmUpdateAddOnsTipsModalState: {
        onCancelClick: () => void
        onConfirmClick: () => Promise<void>
        hasConfirmedChangeCart: boolean
        popUpState: PopUpStatus
    }
    event: EventJSON
    groupSize: number
    onQuantityChange: (id: string, quantity: number) => void
    inventory: Record<string, number>
    isLoading: boolean
    notHaveCategory: (AddOnItemType & AddOnOrderQuantityType)[]
    orderPendingOrCanceledModalState: {
        closePendingOrCanceledOrderModal: () => void
        isPendingOrCanceledOrder: boolean
    }
    roomNumber: number
    selectionFooterComponentState: {
        allowToContinue: boolean
        handelReviewButtOnClick: () => Promise<void>
        priceText: string
        submitting: boolean
    }
    selectAddOnModalState: {
        addOnInModal: AdditionalAddOnType
        addToPackagesHandle: () => void
        allowAdd: (addOnItem: AddOnItemType & AddOnOrderQuantityType) => boolean
        closeModelHandle: () => void
        getQuantity: (itemID: string) => number

        showAddOnModal: boolean
        temporarySelect: Record<string, SelectAddOnType>
        temporarySelectPrice: number
    }
    showOtherMemberAddCartModalState: {
        handleShowOtherMemberAddCartModalOnClick: () => void
        shouldShowOtherMemberAddCartModal: boolean
    }
}

const blankAddOnsInfo = {
    categoryAddOnsItemArray: [],
    description: '',
    imgUrl: '',
    price: 0,
    title: '',
    unitText: ''
}

const blankPopUpState = {
    buttonMessage: '',
    headMessage: '',
    show: false,
    tipsMessage: '',
    tipsUrl: ''
}
export const useAdditionalAddOns = ({
    orderId
}: AdditionalAddOnsProps): AdditionalAddOnsReturnType => {
    const dispatch = useAppDispatch()
    const { getLocalStorage, setLocalStorage } = useLocalStorage()
    const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
    const [authState] = useContext(AuthContext)
    const [addOnItemPrice, setAddOnItemPrice] = useState<number>(0)
    const [addOnInModal, setAddOnInModal] = useState<AdditionalAddOnType>(blankAddOnsInfo)
    const [selectAddOnsItem, setSelectAddOnsItem] = useState<
        Record<string, CartItemWithCategoryType>
    >({})
    const [showAddOnModal, setShowAddOnModal] = useState<boolean>(false)
    const [temporarySelect, setTemporarySelect] = useState<Record<string, SelectAddOnType>>({})
    const [temporarySelectPrice, setTemporarySelectPrice] = useState<number>(0)
    const [priceText, setPriceText] = useState<string>('')
    const [allowToContinue, setAllowToContinue] = useState<boolean>(false)
    const [selectedLimitAddOnQuantity, setSelectedLimitAddOnQuantity] = useState<number>(0)
    const [addOnInventoryErrorItem, setAddOnInventoryErrorItem] = useState<{
        errorItem: AddOnItemType[]
        onRefreshClick: () => void
        show: boolean
    }>()
    const [memberId, setMemberId] = useState<string>('')
    const [order, setOrder] = useState<OrderState>(null!)
    const [event, setEvent] = useState<EventJSON>(null!)
    const [cart, setShoppingCart] = useState<ShoppingCartState>(
        useAppSelector((state) => state.shoppingCartReducer)
    )
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [roomNumber, setRoomNumber] = useState<number>(0)
    const [addOnsItemArray, setAddOnsItemArray] = useState<
        (AddOnItemType & AddOnOrderQuantityType)[]
    >([])
    const [notHaveCategory, setNotHaveCategory] = useState<
        (AddOnItemType & AddOnOrderQuantityType)[]
    >([])
    const [inventory, setInventory] = useState<Record<string, number>>({})
    const [popUpState, setPopUpState] = useState<PopUpStatus>(blankPopUpState)
    const [hasConfirmedChangeCart, setHasConfirmedChangeCart] = useState<boolean>(false)
    const [groupSize, setGroupSize] = useState<number>(0)
    const [isPendingOrCanceledOrder, setIsPendingOrCanceledOrder] = useState<boolean>(false)
    const [isOtherMemberAddCart, setIsOtherMemberViewing] = useState<boolean>(false)
    const [shouldShowOtherMemberAddCartModal, setShouldShowOtherMemberAddCartModal] =
        useState<boolean>(false)
    const [maxLimitationAddOn, setMaxLimitationAddOn] = useState<{
        names: string[]
        show: boolean
    }>()
    const orderUrl: string =
        (typeof window === 'undefined'
            ? serverRuntimeConfig.NEXT_PUBLIC_BACKEND_ORDERS_URL!
            : publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!) || ''
    const isAdditionalOrder = cart.purchaseAddOnsMemberId !== INIT_UUID
    useEffect(() => {
        const load = async () => {
            const orderData = await get<OrderState>(orderUrl, `/me/${orderId}`)
            const cartId = getLocalStorage('shoppingCartID')
            if (orderData.message) {
                router.push('/')
                console.error(orderData.message)
                return
            }

            const isMemberAddCart = await getIsMemberAddCart(orderData.members)
            if (isMemberAddCart) setIsOtherMemberViewing(true)

            const memberId =
                orderData.members.find((member) => {
                    return member.Email.toLowerCase() === authState.user.email.toLowerCase()
                })?.ID || ''
            const cartData: ShoppingCartState = await fetchCart(orderUrl, cartId)(dispatch)
            const { data } = await eventsWithoutJwtClient.query({
                query: GET_ORDER_ID_QUERY,
                variables: {
                    filters: {
                        ids: orderData.eventID
                    },
                    limit: 0,
                    offset: 0
                },
                fetchPolicy: 'no-cache'
            })
            const eventData: EventJSON = data.listEvents.data[0]

            const isOnlySellTickets =
                !eventData?.hotels.some((hotel) => hotel.rooms.length > 0) &&
                !!eventData?.tickets.length &&
                eventData!.tickets.length > 0

            const {inventory: inventoryData} = await getInventory(eventData.id, orderData.groupSize || 1, true)
            let initSelectAddOnsItem: Record<string, CartItemWithCategoryType> = {}
            let displayAddOnsItemArray: (AddOnItemType & AddOnOrderQuantityType)[] = []
            let addOnItemInCartPrice = 0
            const addOnsItemArray: (AddOnItemType & AddOnOrderQuantityType)[] =
                eventData.addOnItems.filter(
                    (addOnsItem: AddOnItemType & AddOnOrderQuantityType) =>
                        !(addOnsItem.pricePerPerson && addOnsItem.pricePerRoom) &&
                        !addOnsItem.hidden &&
                        !addOnsItem.required
                )

            let addOnItemsInOrderMaps = new Map()
            orderData.items.forEach((item) => {
                if (item.type !== 'item') return false
                !addOnItemsInOrderMaps.has(item.itemID)
                    ? addOnItemsInOrderMaps.set(item.itemID, item.quantity)
                    : addOnItemsInOrderMaps.set(
                          item.itemID,
                          item.quantity + addOnItemsInOrderMaps.get(item.itemID)
                      )
            })
            const addOnsItemInOrderArray: any[] = []
            addOnItemsInOrderMaps.forEach((item, index) => {
                addOnsItemInOrderArray.push({
                    itemID: index,
                    quantity: item
                })
            })

            const roomTierItems = orderData.items.filter((item) => item.type === 'room.tier')
            const roomNumber = getRoomQty(roomTierItems)

            addOnsItemArray.forEach((addOnsItem) => {
                let displayAddOnsItem = { ...addOnsItem }
                let quantity = 0
                if (cart.purchaseAddOnsMemberId === memberId) {
                    const addOnItemInCart = cartData.items.find(
                        (item) =>
                            item.itemID === addOnsItem.id && item.type === 'item' && !item.required
                    )
                    quantity = addOnItemInCart?.quantity || 0
                    if (addOnItemInCart) {
                        addOnItemInCartPrice += (addOnItemInCart.price + addOnItemInCart.taxesAndFees) * quantity
                    }
                }
                if (addOnsItem.pricePerRoom && quantity > 0 && quantity !== roomNumber) {
                    quantity = roomNumber
                }
                if (addOnsItem.pricePerRoom) {
                    if (isOnlySellTickets) return
                    displayAddOnsItem.price = addOnsItem.price * roomNumber
                    displayAddOnsItem.taxesAndFees = addOnsItem.taxesAndFees * roomNumber
                }
                addOnsItemInOrderArray.forEach((item) => {
                    if (item.itemID !== addOnsItem.id) return
                    displayAddOnsItem.orderQuantity = item.quantity
                    if (!addOnsItem.pricePerPerson) displayAddOnsItem.orderQuantity = 1
                })
                initSelectAddOnsItem[addOnsItem.id] = {
                    categoryID: addOnsItem.categoryID,
                    itemID: addOnsItem.id,
                    price: addOnsItem.price,
                    pricePerPerson: addOnsItem.pricePerPerson,
                    pricePerRoom: addOnsItem.pricePerRoom,
                    quantity: quantity,
                    taxesAndFees: addOnsItem.taxesAndFees,
                    type: 'item'
                }

                displayAddOnsItemArray.push(displayAddOnsItem)
            })

            if (
                orderData.status === OrderStatus.PENDING ||
                orderData.status === OrderStatus.CANCELED
            )
                setIsPendingOrCanceledOrder(true)

            setShoppingCart({ ...cartData })
            setOrder(orderData)
            setEvent(eventData)
            setMemberId(memberId)
            setGroupSize(orderData.groupSize)
            setInventory(calculateInventoryIncludeCard(inventoryData, cartData.items, undefined))
            setSelectAddOnsItem(initSelectAddOnsItem)
            setAddOnsItemArray(displayAddOnsItemArray)
            setNotHaveCategory(displayAddOnsItemArray.filter((r) => !r.categoryID))
            setRoomNumber(roomNumber)
            setAddOnItemPrice(addOnItemInCartPrice)
            setIsLoading(false)
        }
        try {
            load()
        } catch (error) {
            console.error(error)
            router.push('/')
        }
    }, [])

    useEffect(() => {
        if (!event) return
        let priceText = ''
        let allowToContinue = false
        if (addOnItemPrice > 0) {
            priceText = `\n${handleAmountsPoint(
                formatMoney(addOnItemPrice / 100, event.currency)
            )} Total`
            allowToContinue = true
        }
        setPriceText(priceText)
        setAllowToContinue(allowToContinue)
    }, [addOnItemPrice, event])

    useEffect(() => {
        if (!event) return
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.PAGE_VIEW,
            category: 'AddOns',
            label: window.location.href.split('?')[0],
            pageTitle: event.name + ' - Purchase Add-ons'
        })
        const items: GAProductType[] = event.addOnItems.map(
            (item: AddOnItemType & AddOnOrderQuantityType, index: number) => {
                return {
                    affiliation: APP_NAME,
                    coupon: undefined,
                    discount: undefined,
                    index: index,
                    item_brand: event.name,
                    item_category: `Add Ons`,
                    item_category2: undefined,
                    item_category3: undefined,
                    item_category4: undefined,
                    item_category5: undefined,
                    item_id: item.id,
                    item_list_id: event.id,
                    item_list_name: event.name + ' add ons',
                    item_name: `${item.name}`,
                    item_variant: undefined,
                    location_id: undefined,
                    price: undefined,
                    quantity: item.quantityAvailable
                }
            }
        )
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.VIEW_ITEM_LIST,
            itemListId: event.id,
            itemListName: event.name + ' - Purchase Add-ons List',
            items: items
        })
    }, [event])

    useEffect(() => {
        if (!event) return
        let selectedLimitAddOnQuantity = 0
        const limitAddOnQuantityInOrder = () => {
            addOnsItemArray.forEach((addOn) => {
                const { categoryID, orderQuantity, pricePerPerson, pricePerRoom } = addOn
                const addOnInOrderQuantity = orderQuantity || 0
                if (addOnInOrderQuantity === 0) return
                const category = event.addOnCategories.find(
                    (category) => category.id === categoryID
                )
                if (!category?.groupLimit) return
                if (pricePerPerson) {
                    selectedLimitAddOnQuantity += addOnInOrderQuantity
                    return
                }
                if (pricePerRoom) {
                    selectedLimitAddOnQuantity += roomNumber
                    return
                }
                selectedLimitAddOnQuantity += 1
            })
        }
        const limitAddOnQuantityInSelectAddOn = () => {
            let newSelectAddOnsItem = { ...selectAddOnsItem }
            Object.entries(newSelectAddOnsItem).forEach(([_, addOn]) => {
                const { categoryID, pricePerPerson, pricePerRoom, quantity } = addOn
                if (quantity <= 0) return
                const category = event.addOnCategories.find(
                    (category) => category.id === categoryID
                )
                if (!category?.groupLimit) return
                if (pricePerPerson) {
                    selectedLimitAddOnQuantity += quantity
                    return
                }
                if (pricePerRoom) {
                    selectedLimitAddOnQuantity += roomNumber
                    return
                }
                selectedLimitAddOnQuantity += 1
            })
        }
        limitAddOnQuantityInOrder()
        limitAddOnQuantityInSelectAddOn()
        setSelectedLimitAddOnQuantity(selectedLimitAddOnQuantity)
    }, [addOnsItemArray, event, roomNumber, selectAddOnsItem])

    const addFailedCallback = (error: any) => {
        switch (error) {
            case INVENTORY_SHORTAGE_MESSAGE:
                handleInventoryNotEnough()
                break
            case ORDER_STATUS_IS_CANCELED:
                setIsPendingOrCanceledOrder(true)
                break
            case ADDON_REACHES_THE_upper_LIMIT:
                handleAddOnMaxLimitation()
                break
            default:
                setSubmitting(false)
                setHasConfirmedChangeCart(false)
                break
        }
    }

    const addSuccessCallback = async (shoppingCart: ShoppingCartState, sendToGA: boolean) => {
        if (sendToGA) {
            try {
                googleAnalyticsEvent({
                    action: GoogleAnalyticsEventActionType.ADD_TO_CART,
                    category: event.name,
                    items: handleOrder(event, router.asPath, shoppingCart as unknown as OrderState)
                        .items,
                    label: window.location.href.split('?')[0],
                    pageTitle: event.name + '- Purchase add-ons',
                    value: shoppingCart.totals.subtotal / 100
                })
            } catch (error) {
                console.error(error)
            }
        }
        setLocalStorage('orderId', order.id)
        const localAddToCartInfo = getLocalStorage('addToCartInfo')
        setLocalStorage('addToCartInfo', {
            ...localAddToCartInfo,
            isShowShoppingBag: true,
            shouldShowCountdown: true
        })
        router.push(`/orders/${orderId}/checkout/submit`).catch(() => {
            setSubmitting(false)
        })
    }

    const addToCart = async () => {
        const addOns = Object.values(selectAddOnsItem).filter((item) => item.quantity > 0)
        const addOnsAddToCartItem: AddToCartItem[] = [...addOns]
        const orderId = getLocalStorage('orderId')
        const id = orderId && orderId === cart.id ? reFreshShoppingCart(dispatch) : cart.id
        let newCartData: CartParams = {
            eventID: event.id,
            groupSize: order.groupSize,
            id: id,
            items: addOnsAddToCartItem as CartItemParams[],
            meta: {
                eventName: event.name
            },
            presaleCode: '',
            purchaseAddOnsMemberId: memberId,
        }
        let shoppingCart: ShoppingCartState | { message: any }
        try {
            shoppingCart = await upsertCart(orderUrl, newCartData)(dispatch)
        } catch (error) {
            shoppingCart = {
                message: error
            }
        }
        if ('message' in shoppingCart) {
            addFailedCallback(shoppingCart.message)
        } else {
            addSuccessCallback(shoppingCart, true)
        }
    }

    const addToPackagesHandle = () => {
        setShowAddOnModal(!showAddOnModal)
        let newSelectAddOnsItem = { ...selectAddOnsItem }
        Object.entries(temporarySelect).forEach(([id, addOn]) => {
            if (newSelectAddOnsItem[id].pricePerRoom) {
                newSelectAddOnsItem[id].quantity = roomNumber
            } else {
                newSelectAddOnsItem[id].quantity = addOn.quantity
            }
        })
        const price = Object.entries(newSelectAddOnsItem).reduce((carry, [_, addOn]) => {
            return carry + addOn.quantity * (addOn.price + addOn.taxesAndFees)
        }, 0)

        setAddOnItemPrice(price)
        setSelectAddOnsItem(newSelectAddOnsItem)
        setTimeout(() => {
            setTemporarySelect({})
            setTemporarySelectPrice(0)
        }, 100)
    }

    const allowAdd = (addOnItem: AddOnItemType & AddOnOrderQuantityType) => {
        const { categoryID, id, orderQuantity, pricePerPerson, pricePerRoom, quantityAvailable } =
            addOnItem
        if (isOtherMemberAddCart) return false
        let selectedTotal = 0
        Object.entries(temporarySelect).forEach(([id, selectItem]) => {
            const { quantity } = selectItem
            if (quantity <= 0) return
            const item = event.addOnItems.find((addOn) => addOn.id === id)
            if (!item) return
            const { categoryID, pricePerPerson, pricePerRoom } = item
            const category = event.addOnCategories.find((_category) => _category.id === categoryID)
            if (!category?.groupLimit) return
            if (pricePerPerson) {
                selectedTotal += quantity
                return
            }
            if (pricePerRoom) {
                selectedTotal += roomNumber
                return
            }
            selectedTotal += 1
        })

        const addOnsInCart = cart.items.filter((item) => item.type === 'item' && !item.required)
        let allowToAdd: boolean
        const category = event.addOnCategories.find((category) => category.id === categoryID)
        const addOnInOrderQuantity = orderQuantity || 0
        const addOnItemInCart = addOnsInCart.find((cartItem) => cartItem.itemID === id)
        selectedTotal += selectedLimitAddOnQuantity
        const addOnQuantity = getQuantity(id)
        if (category?.groupLimit) {
            switch (true) {
                case !pricePerRoom && !pricePerPerson:
                    allowToAdd = selectedTotal < groupSize
                    break
                case pricePerRoom:
                    allowToAdd = selectedTotal + roomNumber <= groupSize
                    break
                default:
                    allowToAdd =
                        selectedTotal < groupSize &&
                        addOnQuantity < quantityAvailable &&
                        addOnQuantity < inventory[id] + (addOnItemInCart?.quantity || 0)
                    break
            }
        } else {
            switch (true) {
                case pricePerPerson:
                    allowToAdd =
                        addOnQuantity + addOnInOrderQuantity < groupSize &&
                        addOnQuantity < quantityAvailable &&
                        addOnQuantity < inventory[id] + (addOnItemInCart?.quantity || 0)
                    break
                default:
                    allowToAdd = addOnInOrderQuantity <= 0
                    break
            }
        }
        return allowToAdd
    }

    const closeAddOnLimitModal = () => {
        setMaxLimitationAddOn({
            names: maxLimitationAddOn?.names ?? [''],
            show: false
        })
        location.reload()
    }

    const closeModelHandle = () => {
        setShowAddOnModal(!showAddOnModal)
        setTimeout(() => {
            setTemporarySelect({})
            setTemporarySelectPrice(0)
        }, 100)
    }

    const closePendingOrCanceledOrderModal = () => {
        router.push(`/orders/${orderId}`)
        setIsPendingOrCanceledOrder(false)
    }

    const getGroupLimitAvailableQuantity = () => {
        let groupLimitAvailableQuantity = groupSize
        event.addOnCategories.forEach((addOnCategory) => {
            if (!addOnCategory.groupLimit) return
            const singleCategoryAddOnsItemArray = addOnsItemArray.filter(
                (addOnsItem) => addOnsItem.categoryID === addOnCategory.id
            )
            singleCategoryAddOnsItemArray.forEach((addOnsItem) => {
                const addOnInOrderQuantity = addOnsItem.orderQuantity || 0
                switch (true) {
                    case addOnsItem.pricePerPerson:
                        groupLimitAvailableQuantity -= addOnInOrderQuantity
                        break
                    case addOnsItem.pricePerRoom && addOnInOrderQuantity > 0:
                        groupLimitAvailableQuantity -= roomNumber
                        break
                    case addOnInOrderQuantity > 0:
                        groupLimitAvailableQuantity -= 1
                        break
                    default:
                        break
                }
            })
        })
        return groupLimitAvailableQuantity
    }

    const getIsMemberAddCart = async (members: MemberInfo[]) => {
        if (members.length <= 1) return false
        const memberIds = members
            .map((member) => {
                if (member.Email.toLowerCase() !== authState.user.email.toLowerCase()) {
                    return member.ID
                }
            })
            .filter((id) => id !== undefined) as string[]
        const isPurchasing = await CheckAnyMembersPurchasing(orderUrl, memberIds)
        return isPurchasing
    }

    const getQuantity = (itemID: string) => {
        if (temporarySelect[itemID]) return temporarySelect[itemID].quantity
        return selectAddOnsItem[itemID].quantity
    }

    const handleAddItemOnClick = (
        id: string,
        addOnItem: (AddOnItemType & AddOnOrderQuantityType) | undefined,
        CategoryAddOnsItemArray: (AddOnItemType & AddOnOrderQuantityType)[] | undefined
    ) => {
        let addOnInModal: AdditionalAddOnType = blankAddOnsInfo
        if (CategoryAddOnsItemArray) {
            const addOnCategory = event?.addOnCategories.find((categories) => categories.id === id)!
            const sortCategoryAddOnsItemArray = [...CategoryAddOnsItemArray].sort(
                (a, b) => a.price - b.price
            )
            const lowestPrice = sortCategoryAddOnsItemArray[0].price + sortCategoryAddOnsItemArray[0].taxesAndFees
            const categoryAddOnsItemArray = CategoryAddOnsItemArray.sort(
                (a, b) => a.position - b.position
            )
            const unitText = sortCategoryAddOnsItemArray[0].pricePerPerson ? 'Person' : 'Package'

            addOnInModal = {
                categoryAddOnsItemArray: categoryAddOnsItemArray,
                description: addOnCategory.description,
                imgUrl: addOnCategory.headerImage[0]?.url || '',
                price: lowestPrice,
                title: addOnCategory.title,
                unitText: unitText
            }
        } else if (addOnItem) {
            addOnInModal = {
                categoryAddOnsItemArray: [addOnItem],
                description: addOnItem.description,
                imgUrl: addOnItem.headerImage[0]?.url || '',
                price: addOnItem.price + addOnItem.taxesAndFees,
                title: addOnItem.name,
                unitText: addOnItem.pricePerPerson ? 'Person' : 'Package'
            }

            const gaAddOnItem = {
                affiliation: APP_NAME,
                coupon: undefined,
                discount: undefined,
                index: 0,
                item_brand: addOnItem.name,
                item_category: 'Add Ons',
                item_category2: undefined,
                item_category3: undefined,
                item_category4: undefined,
                item_category5: undefined,
                item_id: addOnItem.id,
                item_list_id: event.id,
                item_list_name: event.name + ' Add Ons List',
                item_name: `${addOnItem.name}`,
                item_variant: undefined,
                location_id: undefined,
                price: undefined,
                quantity: 1
            }
            googleAnalyticsEvent({
                action: GoogleAnalyticsEventActionType.VIEW_ITEM,
                currency: 'USD',
                items: [gaAddOnItem],
                value: addOnItem.price / 100
            })
        }
        setAddOnInModal(addOnInModal)
        setTemporarySelect({})
        setShowAddOnModal(true)
    }

    const handleAddOnMaxLimitation = async () => {
        let orderData: OrderState
        try {
            orderData = await get<OrderState>(orderUrl, `/me/${orderId}`)
            if (orderData.message) {
                router.push('/')
                return
            }
        } catch {
            router.push('/')
            return
        }
        const roomQty = order.items.reduce((carry, item) => {
            if (item.type === 'room') carry += item.quantity
            return carry
        }, 0)
        const orderAddOns = orderData.items.filter((item) => item.type === 'item' && !item.required)
        const addOns = Object.values(selectAddOnsItem).filter((item) => item.quantity > 0)
        const maxLimitationAddOnIds: string[] = []
        const maxLimitationAddOnNames: string[] = []
        addOns.forEach((addOn) => {
            const addOnInOrder = orderAddOns.filter(
                (addOnItem) => addOnItem.itemID === addOn.itemID
            )
            let totalQty = addOn.quantity
            if (addOnInOrder.length > 0) {
                totalQty += addOnInOrder.reduce((carry, addOn) => {
                    return (carry += addOn.quantity)
                }, 0)
            }
            if (addOn.pricePerRoom && totalQty > roomQty) {
                maxLimitationAddOnIds.push(addOn.itemID)
                return
            }
            if (addOn.pricePerPerson && totalQty > orderData.groupSize) {
                maxLimitationAddOnIds.push(addOn.itemID)
                return
            }
            if (!addOn.pricePerPerson && !addOn.pricePerRoom && totalQty > 1) {
                maxLimitationAddOnIds.push(addOn.itemID)
            }
        })
        event.addOnItems.forEach((addOn) => {
            if (maxLimitationAddOnIds.includes(addOn.id)) {
                maxLimitationAddOnNames.push(addOn.name)
            }
        })
        setMaxLimitationAddOn({
            names: maxLimitationAddOnNames,
            show: true
        })
    }

    const handleInventoryNotEnough = async () => {
        const {inventory: inventoryData} = await getInventory(event.id, order.groupSize, true)
        const addOnsInventoryErrorItem = event.addOnItems!.filter(
            (item) => selectAddOnsItem[item.id]?.quantity > inventoryData[item.id]
        )
        setAddOnInventoryErrorItem({
            errorItem: addOnsInventoryErrorItem,
            show: true,
            onRefreshClick: () => {
                location.reload()
            }
        })
    }

    const handleRemoveCart = (id: string, haveCategory: boolean) => {
        let newSelectAddOnsItem = { ...selectAddOnsItem }
        if (haveCategory) {
            Object.entries(newSelectAddOnsItem).forEach(([addOnId]) => {
                if (newSelectAddOnsItem[addOnId].categoryID === id) {
                    newSelectAddOnsItem[addOnId].quantity = 0
                }
            })
        } else {
            newSelectAddOnsItem[id].quantity = 0
        }
        const price = Object.entries(newSelectAddOnsItem).reduce((carry, [_, addOn]) => {
            return carry + addOn.quantity * (addOn.price + addOn.taxesAndFees)
        }, 0)
        removeFromMparticle()
        setAddOnItemPrice(price)
        setSelectAddOnsItem(newSelectAddOnsItem)
    }

    const handelReviewButtOnClick = async () => {
        if (submitting) return
        setSubmitting(true)
        const isMemberAddCart = await getIsMemberAddCart(order.members)
        if (isMemberAddCart) {
            setShouldShowOtherMemberAddCartModal(true)
            setIsOtherMemberViewing(true)
            setAddOnItemPrice(0)
            setSubmitting(false)
            return
        }
        // ----------- check and add to cart -----------------
        const latestShoppingCart = await fetchCart(orderUrl, cart.id)(dispatch)
        const addOns = Object.values(selectAddOnsItem).filter((item) => item.quantity > 0)
        const overGroupSizePersonAddOnItems = addOns.filter((addOnItem) => {
            return event.addOnItems
                .filter((item) => item.pricePerPerson)
                .find((item) => item.id === addOnItem.itemID && addOnItem.quantity > groupSize)
        })

        if (overGroupSizePersonAddOnItems.length > 0) {
            let message = ''
            overGroupSizePersonAddOnItems.forEach((addOnsItem, index) => {
                const addOnItem = event!.addOnItems.find((item) => item.id === addOnsItem.itemID)!
                message =
                    message +
                    `${groupSize} ` +
                    addOnItem.name +
                    (index !== overGroupSizePersonAddOnItems.length - 1 ? ', ' : ' ')
            })

            setPopUpState({
                buttonMessage: 'Select Add-Ons',
                headMessage: '',
                show: true,
                tipsMessage: `You can only purchase ${message} at most.`,
                tipsUrl: `/events/${event.slug}/add-ons`
            })
            setSubmitting(false)
            return
        }

        const latestAddOnItems = latestShoppingCart.items
            .filter((item) => item.type === 'item' && !item.required)
            .map((item) => {
                return {
                    itemID: item.itemID,
                    price: item.price,
                    quantity: item.quantity
                }
            })
            .sort((a, b) => b.price - a.price)
        const selectedAddOnItems = addOns
            .map((addOn) => {
                return {
                    itemID: addOn.itemID,
                    price: addOn.price,
                    quantity: addOn.quantity
                }
            })
            .sort((a, b) => b.price - a.price)

        const cartHasChange = !isAdditionalOrder && latestShoppingCart.items.length > 0
        if (latestAddOnItems.length || cartHasChange) {
            let addOnsItemsHasChange =
                !isEqual(latestAddOnItems, selectedAddOnItems) || cartHasChange
            switch (true) {
                case addOnsItemsHasChange:
                    setPopUpState({
                        buttonMessage: 'Confirm',
                        headMessage: 'Replace cart',
                        show: true,
                        tipsMessage:
                            'Adding this item to your cart will replace your current cart. This action cannot be undone.',
                        tipsUrl: '~'
                    })
                    setHasConfirmedChangeCart(true)
                    break
                default:
                    addSuccessCallback(latestShoppingCart, false)
                    break
            }
        } else {
            addToCart()
        }
    }

    const handleShowOtherMemberAddCartModalOnClick = () => {
        setShouldShowOtherMemberAddCartModal(false)
    }

    const notCategoryNotPerson = (id: string) => {
        let newSelectAddOnsItem = { ...selectAddOnsItem }
        let price = 0
        if (newSelectAddOnsItem[id].pricePerRoom) {
            newSelectAddOnsItem[id].quantity = roomNumber
        } else {
            newSelectAddOnsItem[id].quantity += 1
        }
        Object.entries(newSelectAddOnsItem).forEach(([_, addOn]) => {
            price += addOn.quantity * (addOn.price + addOn.taxesAndFees)
        })
        setAddOnItemPrice(price)
        setSelectAddOnsItem(newSelectAddOnsItem)
    }

    const onCancelClick = () => {
        setPopUpState({ ...popUpState, show: false })
        setSubmitting(false)
    }

    const onConfirmClick = async () => {
        await addToCart()
        switch (true) {
            case popUpState.tipsUrl === '':
                location.reload()
                break
            case popUpState.tipsUrl.startsWith('/'):
                router.push(popUpState.tipsUrl)
                break
            default:
                break
        }
        setPopUpState({ ...popUpState, show: false })
    }

    const onQuantityChange = (id: string, quantity: number) => {
        let newTemporarySelect = { ...temporarySelect }
        let temporaryPrice = 0

        if (quantity > 0) {
            if (newTemporarySelect[id]) {
                newTemporarySelect[id].quantity = quantity
            } else {
                const item = addOnsItemArray.find((addOn) => addOn.id === id) ?? {
                    price: 0,
                    taxesAndFees: 0
                }
                newTemporarySelect[id] = {
                    itemID: id,
                    price: item.price,
                    quantity: quantity,
                    taxesAndFees: item.taxesAndFees
                }
            }
        } else {
            delete newTemporarySelect[id]
        }
        Object.entries(newTemporarySelect).forEach(([id, addOn]) => {
            temporaryPrice += (addOn.price + addOn.taxesAndFees) * addOn.quantity
        })
        setTemporarySelectPrice(temporaryPrice)
        setTemporarySelect(newTemporarySelect)
    }

    const removeFromMparticle = () => {
        try {
            googleAnalyticsEvent({
                action: GoogleAnalyticsEventActionType.REMOVE_FROM_CART,
                category: event.name,
                items: handleOrder(event, router.asPath, cart as unknown as OrderState).items,
                label: window.location.href.split('?')[0],
                pageTitle: event.name + 'Purchase Add-ons',
                value: cart.totals.subtotal / 100
            })
        } catch (error) {
            console.error(error)
        }
    }

    const addOnCartState = {
        getGroupLimitAvailableQuantity,
        handleAddItemOnClick,
        handleRemoveCart,
        isOtherMemberAddCart,
        notCategoryNotPerson,
        selectAddOnsItem
    }
    const addOnLimitModalState = {
        closeAddOnLimitModal,
        maxLimitationAddOn
    }
    const confirmUpdateAddOnsTipsModalState = {
        onCancelClick,
        onConfirmClick,
        hasConfirmedChangeCart,
        popUpState
    }
    const orderPendingOrCanceledModalState = {
        closePendingOrCanceledOrderModal,
        isPendingOrCanceledOrder
    }
    const selectAddOnModalState = {
        addOnInModal,
        addToPackagesHandle,
        allowAdd,
        closeModelHandle,
        getQuantity,
        // onQuantityChange,
        showAddOnModal,
        temporarySelect,
        temporarySelectPrice
    }

    const selectionFooterComponentState = {
        allowToContinue,
        handelReviewButtOnClick,
        priceText,
        submitting
    }
    const showOtherMemberAddCartModalState = {
        handleShowOtherMemberAddCartModalOnClick,
        shouldShowOtherMemberAddCartModal
    }
    return {
        addOnCartState,
        addOnInventoryErrorItem,
        addOnLimitModalState,
        addOnsItemArray,
        cart,
        confirmUpdateAddOnsTipsModalState,
        event,
        groupSize,
        onQuantityChange,
        isLoading,
        inventory,
        notHaveCategory,
        orderPendingOrCanceledModalState,
        roomNumber,
        selectAddOnModalState,
        selectionFooterComponentState,
        showOtherMemberAddCartModalState
    }
}
