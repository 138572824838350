export const TRAVEL_GUARD_MAX_AGE = 124
export const PLEASE_ENTER_VALID_BIRTHDATE = `Please provide a valid date of birth (We cover travelers 0-${TRAVEL_GUARD_MAX_AGE} years old)`
export const PLEASE_SELECT_INSURANCE_OPTIONS =
    'Please select one of the travel insurance options below.'

// link
export const CLAIMS_OR_CONTACT_LINK =
    'https://claims.travelguard.com/direct/us/en/claimsv2/home?afAcceptLang=en-us'
export const LEGAL_DISCLAIMER_LINK = 'https://www.travelguard.com/legal/disclaimer'
export const POLICY_OF_INSURANCE_LINK =
    'https://webservices.travelguard.com/Product/FileRetrieval.aspx?CountryCode=US&StateCode=AL&ProductCode=939802&PlanCode=NW21&FileType=PROD_PLAN_GM'

export const TRAVELGUARD_POST_PURCHASE_LINK =
    'https://advisors.travelguard.com/buy?pcode=&ta_arc=181156'
