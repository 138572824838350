import { Dispatch } from 'react'
import { AddToCartInfo, AddToCartItem, ShoppingCartItem, ShoppingCartState } from '../store'
import { v4 as uuid } from 'uuid'
import { isEqual } from 'lodash'
import { EventJSON } from '../../../events-service/src/models/event'
import { TicketJSON } from '../../../events-service/src/models/ticket'
import { TierJSON } from '../../../events-service/src/models/tier'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { HotelRoomJSON } from '../../../events-service/src/models/hotelRoom'
import { HotelRoomTierJSON } from '../../../events-service/src/models/hotelRoomTier'
import { AddOnItemType } from '../../../events-service/src/models/addOnItem'
import { ItemJSON } from '../../../events-service/src/models/item'
import { ResortFee } from 'enums'

export function reFreshShoppingCart(dispatch: Dispatch<any>) {
    const newShoppingCartID = uuid()
    dispatch({ type: 'shoppingCart/refreshCartID', payload: newShoppingCartID })
    return newShoppingCartID
}

export function checkLocalStorage(
    localAddToCartInfo: AddToCartInfo,
    latestLocalAddToCartInfo: AddToCartInfo,
    isOnlySellTickets: boolean = false
) {
    // localAddToCartInfo for page rendering
    const [localTicketItems, localRoomItems, localAddOnItems, localEventSlug, localEventID] = [
        localAddToCartInfo?.ticketItems || [],
        localAddToCartInfo?.roomTierItems || [],
        localAddToCartInfo?.addOnItems || [],
        localAddToCartInfo?.eventSlug,
        localAddToCartInfo?.eventID
    ]
    const addToCartData = [...localTicketItems, ...localRoomItems, ...localAddOnItems]

    // localAddToCartInfo for page rendering
    const [
        latestLocalTicketItems,
        latestLocalRoomItems,
        latestLocalAddOntems,
        latestStayOnAddOnsPage,
        latestLocalEventID,
        latestLocalEventSlug
    ] = [
        latestLocalAddToCartInfo?.ticketItems || [],
        latestLocalAddToCartInfo?.roomTierItems || [],
        latestLocalAddToCartInfo?.addOnItems || [],
        latestLocalAddToCartInfo?.stayOnAddOnsPage || false,
        latestLocalAddToCartInfo?.eventID,
        latestLocalAddToCartInfo?.eventSlug
    ]

    const latestAddToCartData = [
        ...latestLocalTicketItems,
        ...latestLocalRoomItems,
        ...latestLocalAddOntems
    ]

    const localCartHasConflict =
        localTicketItems.length !== latestLocalTicketItems.length ||
        localRoomItems.length !== latestLocalRoomItems.length ||
        localAddOnItems.length !== latestLocalAddOntems.length ||
        !addToCartData.every((item) =>
            latestAddToCartData.find(
                (i) => i.itemID === item.itemID && i.quantity === item.quantity
            )
        ) ||
        localAddToCartInfo.groupSize !== latestLocalAddToCartInfo.groupSize

    if (localCartHasConflict) {
        let url =
            `/events/${latestLocalEventSlug}/checkout/preview` ||
            `/events/${localEventSlug}/checkout/preview`

        if (localEventID !== latestLocalEventID) {
            url = `/events/${latestLocalEventSlug}` || `/events/${localEventSlug}`
        } else if (latestStayOnAddOnsPage) {
            url = `/events/${latestLocalEventSlug}/add-ons` || `/events/${localEventSlug}/add-ons`
        }

        return { isLocalCartHasConflict: localCartHasConflict, url: url }
    }
    return { isLocalCartHasConflict: false, url: '' }
}

export function checkIfOnlyAddonsInCartChanged(
    latestShoppingCart: ShoppingCartState,
    addOns: AddToCartItem[]
) {
    const latestAddOns = latestShoppingCart.items.filter(
        (i) => i.type === 'item'
    ) as AddToCartItem[]
    const addToCartData = [...addOns]
    const cartData = [...latestAddOns]

    const cartItemsHasChange =
        addOns.length !== latestAddOns.length ||
        !addToCartData.every((item) =>
            cartData.find((i) => i.itemID === item.itemID && i.quantity === item.quantity)
        )

    return cartItemsHasChange
}

export function compareCart(
    latestShoppingCart: ShoppingCartState,
    localTicketItems: AddToCartItem[],
    localRoomItems: AddToCartItem[],
    addOns: AddToCartItem[],
    isOnlySellTickets: boolean
) {
    const latestTickets = latestShoppingCart.items.filter(
        (i) => i.type === 'ticket.tier'
    ) as AddToCartItem[]
    const latestRooms = latestShoppingCart.items.filter(
        (i) => i.type === 'room.tier'
    ) as AddToCartItem[]
    const latestAddOns = latestShoppingCart.items.filter(
        (i) => i.type === 'item'
    ) as AddToCartItem[]
    // Determine whether the shopping cart will be changed
    const addToCartData = [
        ...localTicketItems,
        ...(isOnlySellTickets ? [] : localRoomItems),
        ...addOns
    ]
    const cartData = [...latestTickets, ...(isOnlySellTickets ? [] : latestRooms), ...latestAddOns]
    console.log({ addOns })

    const cartItemsHasChange =
        localTicketItems.length !== latestTickets.length ||
        (!isOnlySellTickets && localRoomItems.length !== latestRooms.length) ||
        addOns.length !== latestAddOns.length ||
        !addToCartData.every((item) =>
            cartData.find((i) => i.itemID === item.itemID && i.quantity === item.quantity)
        )

    return cartItemsHasChange
}

export function compareCartItemsHasChange(
    latestShoppingCart: ShoppingCartState,
    selectedTicketItems: AddToCartItem[],
    selectedRoomItems: AddToCartItem[],
    selectedAddOnItems: AddToCartItem[]
) {
    type Item = {
        itemID: string
        price: number
        quantity: number
    }

    let latestTicketItems: Item[] = []
    let latestRoomItems: Item[] = []
    let latestAddOnItems: Item[] = []
    latestShoppingCart.items.forEach((i) => {
        let item = {
            itemID: i.itemID,
            price: i.price,
            quantity: i.quantity
        }
        if (i.type === 'ticket.tier') {
            latestTicketItems.push(item)
        } else if (i.type === 'room.tier') {
            latestRoomItems.push(item)
        } else if (i.type === 'item') {
            latestAddOnItems.push(item)
        }
    })

    let clearRoomItems = selectedRoomItems.map((r) => {
        return {
            itemID: r.itemID,
            price: r.price,
            quantity: r.quantity
        }
    })

    let clearAddOnItems = selectedAddOnItems.map((a) => {
        return {
            itemID: a.itemID,
            price: a.price,
            quantity: a.quantity
        }
    })

    let clearTicketItems = selectedTicketItems.map((t) => {
        return {
            itemID: t.itemID,
            price: t.price,
            quantity: t.quantity
        }
    })
    console.log({
        latestTicketItems,
        clearTicketItems,
        latestRoomItems,
        clearRoomItems,
        latestAddOnItems,
        clearAddOnItems
    })
    return (
        !isEqual(latestTicketItems, clearTicketItems) ||
        !isEqual(latestRoomItems, clearRoomItems) ||
        !isEqual(latestAddOnItems, clearAddOnItems)
    )
}

export const getExperiencePrice = (items: ShoppingCartItem[]) => {
    let experiencePrice = 0
    items
        .filter((item) => item.type === 'ticket.experience.tier')
        .map((item) => {
            experiencePrice += item.quantity * item.price
        })
    return experiencePrice
}

export const getTicketTierPrice = (items: ShoppingCartItem[]) => {
    let ticketTierPrice = 0
    items
        .filter((item) => item.type === 'ticket.tier')
        .map((item) => {
            ticketTierPrice += item.quantity * item.price
        })
    return ticketTierPrice
}

export const debounce = (func: any, delay: number) => {
    let timer: NodeJS.Timeout
    return (...args: any) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            func(...args)
        }, delay)
    }
}

export const getCheckoutItemName = (
    event: EventJSON,
    item: { itemID?: string; id?: string; name?: string }
) => {
    if (item.name) return item.name
    const nameItem:
        | EventJSON
        | TicketJSON
        | TierJSON
        | HotelJSON
        | HotelRoomJSON
        | HotelRoomTierJSON
        | AddOnItemType
        | ItemJSON
        | undefined = [
        event,
        ...event.tickets,
        ...event.tickets.flatMap((ticket) => ticket.tiers),
        ...event.hotels,
        ...event.hotels.flatMap((hotel) => hotel.rooms),
        ...event.hotels.flatMap((hotel) => hotel.rooms.flatMap((room) => room.tiers)),
        ...event.addOnItems,
        ...event.items
    ].find((eventItem) => eventItem.id === item.itemID || eventItem.id === item?.id)
    if (nameItem) return nameItem.name
    return ''
}

export const getTotalResortFee = (
    resortFee: number,
    resortFeeType: string,
    roomSize: number,
    nights: number
) => {
    let totalResortFee = 0
    switch (resortFeeType) {
        case ResortFee.PerRoom:
            totalResortFee = resortFee * roomSize * nights
            break
        case ResortFee.PerOrder:
            totalResortFee = resortFee * nights
            break
    }
    return totalResortFee
}

export const getTotalPorterageFee = (
    porterageFee: number,
    porterageFeeType: string,
    roomSize: number,
    nights: number
) => {
    let totalPorterageFee = 0
    switch (porterageFeeType) {
        case ResortFee.PerRoom:
            totalPorterageFee = porterageFee * roomSize * nights
            break
        case ResortFee.PerOrder:
            totalPorterageFee = porterageFee * nights
            break
    }
    return totalPorterageFee
}
