import { EventJSON } from '../../../events-service/src/models/event'
import { getImage } from '../util/getEventHeaderImageByDevice'

export default function EventBottomHero({
    bottomImage
}: {
    bottomImage: EventJSON['bottomImage']
}) {
    return (
        <div className="container footer-event-container footer-event-container-new">
            <div className="row">
                <div className="col-12 gx-0 gx-md-4-2 text-center">
                    <picture>
                        <source
                            media="(min-width:1025px)"
                            srcSet={getImage(bottomImage, 'desktop').url}
                        />
                        <source
                            media="(min-width:768px)"
                            srcSet={getImage(bottomImage, 'tablet').url}
                        />
                        <img
                            src={getImage(bottomImage, 'mobile').url}
                            className="img-fluid bg-gray"
                            alt=""
                        />
                    </picture>
                </div>
            </div>
        </div>
    )
}
