import dayjs from 'dayjs'

export const ALL_HOTELS = 'allHotels'
export const ALL_ROOMS = 'allRooms'
export const ALL_TICKETS = 'allTickets'
export const LATEST_DATE_TO_BE_SOLD = 'latestDateToBeSold'
export const ON_SALE = 'onSale'
export const SOLD_OUT = 'soldOut'
export const WILL_BE_SOLD = 'willBeSold'

export const defaultHotelNumObject = {
    [ALL_HOTELS]: 0,
    [LATEST_DATE_TO_BE_SOLD]: dayjs().toDate(),
    [ON_SALE]: 0,
    [SOLD_OUT]: 0,
    [WILL_BE_SOLD]: 0
}

export const defaultTicketNumObject = {
    [ALL_TICKETS]: 0,
    [LATEST_DATE_TO_BE_SOLD]: dayjs().toDate(),
    [ON_SALE]: 0,
    [SOLD_OUT]: 0,
    [WILL_BE_SOLD]: 0
}

export const EVENT_TYPES = {
    HOTELS_AND_TICKETS: 'hotelsAndTickets',
    ONLY_HOTELS: 'onlyHotels',
    ONLY_TICKETS: 'onlyTickets'
}
