import { EventJSON } from '../../../events-service/src/models/event'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { ShoppingCartItem } from '../store'
import { isFutureDate } from './dates'
import { getEarliestOnSaleDate } from './pricing'
import { TicketJSON } from '../../../events-service/src/models/ticket'
import dayjs from 'dayjs'
import {
    ALL_HOTELS,
    ALL_ROOMS,
    ALL_TICKETS,
    LATEST_DATE_TO_BE_SOLD,
    ON_SALE,
    SOLD_OUT,
    WILL_BE_SOLD
} from 'consts'
import { HotelRoomJSON } from '../../../events-service/src/models/hotelRoom'
import { HotelRoomTierPriceJSON } from '../../../events-service/src/models/hotelRoomTierPrice'
import { checkTourData } from 'pages/events/[slug]'
const GUEST_OPTIONS = [
    { value: 1, label: '1 Guest', isDisabled: false },
    { value: 2, label: '2 Guests', isDisabled: false },
    { value: 3, label: '3 Guests', isDisabled: false },
    { value: 4, label: '4 Guests', isDisabled: false },
    { value: 5, label: '5 Guests', isDisabled: false },
    { value: 6, label: '6 Guests', isDisabled: false },
    { value: 7, label: '7 Guests', isDisabled: false },
    { value: 8, label: '8 Guests', isDisabled: false }
]

type HotelData = {
    [roomID: string]: {price:number}
  };
  
  type HotelsData = {
    [hotelID: string]: HotelData;
  };
  

export const getDepositAmount = (e: EventJSON): number => {
    if (!e || !e.layawayDates || e.layawayDates.length === 0) {
        return 0
    }
    const now = new Date()
    const dates = [...e.layawayDates]
        .sort((a, b) => {
            return new Date(a.date).valueOf() - new Date(b.date).valueOf()
        })
        .map((d) => {
            return {
                ...d,
                date: new Date(d.date)
            }
        })
    for (let i = 0; i < e.layawayDates.length; i++) {
        if (dates[i].date.valueOf() > now.valueOf() && i !== 0) {
            return dates[i - 1].depositAmount
        }
    }
    return dates[dates.length - 1].depositAmount
}

export const getDepositIsPercentage = (e: EventJSON): boolean => {
    if (!e || !e.layawayDates || e.layawayDates.length === 0) {
        return false
    }
    const now = new Date()
    const dates = [...e.layawayDates]
        .sort((a, b) => {
            return new Date(a.date).valueOf() - new Date(b.date).valueOf()
        })
        .map((d) => {
            return {
                ...d,
                date: new Date(d.date)
            }
        })
    for (let i = 0; i < e.layawayDates.length; i++) {
        if (dates[i].date.valueOf() > now.valueOf() && i !== 0) {
            return dates[i - 1].depositIsPercentage
        }
    }
    return dates[dates.length - 1].depositIsPercentage
}

export const calculateInventoryIncludeCard = (
    inventory: Record<string, number>,
    cartItem: ShoppingCartItem[],
    hotel: HotelJSON[] | undefined
) => {
    if (cartItem?.length === 0 || !cartItem) return inventory
    let newInventory = JSON.parse(JSON.stringify(inventory))
    cartItem.map((item) => {
        if (newInventory[item.itemID]) {
            newInventory[item.itemID] += item.quantity
        } else {
            newInventory[item.itemID] = item.quantity
        }
        if (item.type === 'room.tier.price' && hotel) {
            hotel
                .flatMap((h) => h.rooms)
                .flatMap((r) => r.tiers)
                .map((t) => {
                    t.prices?.map((p) => {
                        if (p.id === item.itemID && p.quantityAvailable === -1) {
                            t.prices.map((n) => {
                                if (n.quantityAvailable === -1 && n.id !== item.itemID) {
                                    if (newInventory[n.id]) {
                                        newInventory[n.id] += item.quantity
                                    } else {
                                        newInventory[n.id] = item.quantity
                                    }
                                }
                            })
                        }
                    })
                })
        }
    })
    return newInventory
}

export const getAdditionalShoulderNightsFromItem = (item: any[]) => {
    if (item.length === 0) return 0
    let shouldNightsCount = 0
    const roomNumber = item.reduce((pre, cur) => {
        if (cur.type === 'room') return pre + 1
        if (cur.type === 'room.shouldernight') shouldNightsCount += 1
        return pre
    }, 0)
    return shouldNightsCount / roomNumber
}

export const getEventNameOverride = (
    event: EventJSON | undefined,
    selectedTicketNumber: string
) => {
    if (!event) {
        return ''
    }
    const eventNameOverride =
        event.tickets.find((ticket) => {
            return ticket.id === selectedTicketNumber
        })?.eventNameOverride || ''
    return eventNameOverride
}

export const isTicketSoldOut = (
    tickets: { tiers: { id: string }[] },
    inventory: Record<string, number>
) => {
    if (Object.entries(inventory).length === 0) return true

    return (
        tickets.tiers.filter((ticket) => {
            if (!inventory[ticket.id]) return true
            return inventory[ticket.id] === 0
        }).length === tickets.tiers.length
    )
}

export const isRoomSoldOut = (
    room: { tiers: { id: string; prices: HotelRoomTierPriceJSON[] }[] },
    inventory: Record<string, number>
) => {
    return (
        room.tiers.filter(
            (tier) =>
                tier.prices.filter((price) => inventory[price.id] === 0).length ===
                tier.prices.length
        ).length === room.tiers.length
    )
}
export const getHotelNumInfos = (
    event: EventJSON | undefined,
    inventory: Record<string, number>
) => {
    const initDate = dayjs().toDate()
    const hotelNumInfos = {
        [ALL_HOTELS]: 0,
        [LATEST_DATE_TO_BE_SOLD]: initDate,
        [ON_SALE]: 0,
        [SOLD_OUT]: 0,
        [WILL_BE_SOLD]: 0
    }
    const getRoomNumInfos = (hotel: HotelJSON) => {
        let roomNumInfos = {
            [ALL_ROOMS]: 0,
            [LATEST_DATE_TO_BE_SOLD]: initDate,
            [ON_SALE]: 0,
            [SOLD_OUT]: 0,
            [WILL_BE_SOLD]: 0
        }
        hotel.rooms.forEach((room) => {
            const earliestOnSaleDate = getEarliestOnSaleDate(room)
            roomNumInfos[ALL_ROOMS] += 1
            const isSoldOnFuture = earliestOnSaleDate && isFutureDate(earliestOnSaleDate)
            switch (true) {
                case !room.onSale:
                    roomNumInfos[SOLD_OUT] += 1
                    break
                case isSoldOnFuture:
                    roomNumInfos[WILL_BE_SOLD] += 1
                    if (
                        roomNumInfos[LATEST_DATE_TO_BE_SOLD] === initDate ||
                        roomNumInfos[LATEST_DATE_TO_BE_SOLD] > earliestOnSaleDate
                    ) {
                        roomNumInfos[LATEST_DATE_TO_BE_SOLD] = earliestOnSaleDate
                    }
                    break
                case isRoomSoldOut(room, inventory):
                    roomNumInfos[SOLD_OUT] += 1
                    break
                default:
                    roomNumInfos[ON_SALE] += 1
                    break
            }
        })
        return roomNumInfos
    }
    event?.hotels.forEach((hotel) => {
        hotelNumInfos[ALL_HOTELS] += 1
        const roomNumInfos = getRoomNumInfos(hotel)
        switch (true) {
            case roomNumInfos[SOLD_OUT] === roomNumInfos[ALL_ROOMS]:
                hotelNumInfos[SOLD_OUT] += 1
                break
            case roomNumInfos[WILL_BE_SOLD] + roomNumInfos[SOLD_OUT] === roomNumInfos[ALL_ROOMS]:
                hotelNumInfos[WILL_BE_SOLD] += 1
                if (
                    hotelNumInfos[LATEST_DATE_TO_BE_SOLD] === initDate ||
                    hotelNumInfos[LATEST_DATE_TO_BE_SOLD] > roomNumInfos[LATEST_DATE_TO_BE_SOLD]
                ) {
                    hotelNumInfos[LATEST_DATE_TO_BE_SOLD] = roomNumInfos[LATEST_DATE_TO_BE_SOLD]
                }
                break
            default:
                hotelNumInfos[ON_SALE] += 1
                break
        }
    })
    return hotelNumInfos
}

export const getTicketNumInfo = (
    eventTickets: TicketJSON[] | undefined,
    inventory: Record<string, number>,
    event: any
) => {
    const ticketNumInfos = {
        [ALL_TICKETS]: 0,
        [LATEST_DATE_TO_BE_SOLD]: dayjs().toDate(),
        [ON_SALE]: 0,
        [SOLD_OUT]: 0,
        [WILL_BE_SOLD]: 0,
        ticketSoldOutArray: [] as any
    }

    eventTickets?.forEach((ticket) => {
        if (ticket.hidden) return
        const getPriceData = GUEST_OPTIONS.map((res) => {
            const responseArr = checkTourData(event, res.value, inventory, ticket)
            return responseArr
        })
        ticketNumInfos.ticketSoldOutArray.push(...getPriceData)
        const earliestOnSaleDate = getEarliestOnSaleDate(ticket)
        const isSoldOnFuture = earliestOnSaleDate && isFutureDate(earliestOnSaleDate)
        ticketNumInfos[ALL_TICKETS] += 1
        switch (true) {
            case isSoldOnFuture:
                if (
                    ticketNumInfos[WILL_BE_SOLD] === 0 ||
                    ticketNumInfos[LATEST_DATE_TO_BE_SOLD] > earliestOnSaleDate
                ) {
                    ticketNumInfos[LATEST_DATE_TO_BE_SOLD] = earliestOnSaleDate
                }
                ticketNumInfos[WILL_BE_SOLD] += 1
                break
            case isTicketSoldOut(ticket, inventory):
                ticketNumInfos[SOLD_OUT] += 1
                break
            default:
                ticketNumInfos[ON_SALE] += 1
        }
    })
    return ticketNumInfos
}

export const isAllRoomsSoldOnFuture = (rooms: HotelRoomJSON[]) => {
    if (rooms.length === 0) return false
    return rooms.every((room) => {
        const earliestOnSaleDate = getEarliestOnSaleDate(room)
        if (!earliestOnSaleDate) return false
        return isFutureDate(earliestOnSaleDate)
    })
}

export const isAllRoomsSoldOut = (inventory: Record<string, number>, rooms: HotelRoomJSON[]) => {
    return rooms.every((room) => isRoomSoldOut(room, inventory))
}

export const getEarliestRoomOnSaleDate = (rooms: HotelRoomJSON[]) => {
    const initDate = dayjs().toDate()
    let earliestRoomOnSaleDate: Date = initDate
    rooms.forEach((room) => {
        const earliestOnSaleDate = getEarliestOnSaleDate(room)
        const isSoldOnFuture = earliestOnSaleDate && isFutureDate(earliestOnSaleDate)
        if (!isSoldOnFuture) return
        if (earliestRoomOnSaleDate === initDate || earliestRoomOnSaleDate > earliestOnSaleDate) {
            earliestRoomOnSaleDate = earliestOnSaleDate
        }
    })
    return earliestRoomOnSaleDate
}

export const checkMinOccupancy = (
    room: {
        minimumOccupancy: any
        numberOfBeds: number
        tiers: { id: string; prices: HotelRoomTierPriceJSON[]; onSale: boolean }[]
    },
    inventory: any,
    ticketTotalQuantity: number
) => {
    const minimumOccupancy = room.minimumOccupancy
    return room.tiers.some(
        (tier) =>
            tier.onSale &&
            tier.prices.some(
                (price) =>
                    inventory[price.id] >= minimumOccupancy &&
                    ticketTotalQuantity >= minimumOccupancy &&
                    inventory[price.id] >= room.numberOfBeds
            )
    )
}

export const getAvailableRooms = (
    inventory: Record<string, number>,
    isCalculatePrice: boolean,
    rooms: HotelRoomJSON[],
    ticketId: string
) => {
    const availableRooms = rooms.filter((room) => {
        const isRoomOnSale = room.onSale && room.tiers.some((tier) => tier.onSale)
        if (!isRoomOnSale) return false
        if (!isCalculatePrice && isRoomSoldOut(room, inventory)) return false
        const haveAvailableRoom =
            room.tickets.length === 0 || room.tickets.some((ticket) => ticket.id === ticketId)
        return haveAvailableRoom
    })
    return availableRooms
}

export const getMinOccupancyFlag = (
    inventory: Record<string, number>,
    rooms: HotelRoomJSON[],
    ticketId: string
) => {
    const availableRooms = rooms.some((room) => {
        return checkMinOccupancy(room, inventory, inventory[ticketId])
    })
    return availableRooms
}

export function getMinPriceForTicketId(data: HotelsData, ticketId: string, index: number) {
    let minAvailablePrice: number | null = null;
    let minPrice: number | null = null;
    let isAvailable: boolean = false;

    // First pass: check for the lowest available price
    for (const hotel of Object.values(data)) {
        const hotelTicket = hotel[ticketId]?.[index];

        // If the ticket exists for this index and has a valid price and availability
        if (hotelTicket) {
            const { price, isAvailable: availability } = hotelTicket;

            if (availability) {
                // If available, check if it's the lowest available price
                if (minAvailablePrice === null || price < minAvailablePrice) {
                    minAvailablePrice = price;
                    isAvailable = true;
                }
            }

            // Always track the lowest price (even if unavailable)
            if (minPrice === null || price < minPrice) {
                minPrice = price;
            }
        }
    }

    // Return the lowest available price if found, otherwise return the lowest overall price
    return {
        minPrice: minAvailablePrice !== null ? minAvailablePrice : minPrice,
        isAvailable
    };
}
