import { useCallback, useEffect, useMemo, useState } from 'react'
import { EventJSON } from '../../../events-service/src/models/event'
import { TicketJSON } from '../../../events-service/src/models/ticket'
import { getEarliestOnSaleDate } from 'util/pricing'
import { getTimeZone, timeZoneString } from 'util/dates'
import { cardButtonStatus } from 'enums'
import {
    getAvailableRooms,
    getEarliestRoomOnSaleDate,
    isAllRoomsSoldOnFuture,
    isTicketSoldOut
} from 'util/event'

type UseTicketStatusProps = {
    event: EventJSON
    inPreSaleInterval: boolean
    inventory: Record<string, number>
    isCodeValid: boolean
    isOnlySellHotel: boolean
    isOnlySellTickets: boolean
    isPreSale: boolean
    minPricesRef: any
}

export const defaultTicketState = {
    buttonStatus: '',
    price: 0,
    shouldShowPrice: false,
    ticketId: ''
}

export type TicketStatusType = {
    buttonStatus: string
    price: number
    shouldShowPrice: boolean
    ticketId: string
}

export const useTicketStatus = ({
    event,
    inPreSaleInterval,
    inventory,
    isCodeValid,
    isOnlySellHotel,
    isOnlySellTickets,
    isPreSale,
    minPricesRef
}: UseTicketStatusProps): TicketStatusType[] => {
    const { hotels, tickets, timezone } = event
    const [ticketsStatus, setTicketsStatus] = useState<TicketStatusType[]>([])
    const getTicketsAssociateRooms = useCallback(
        (ticket: TicketJSON) => {
            return hotels
                .filter((hotel) => {
                    return (
                        hotel.tickets.length === 0 ||
                        hotel.tickets.some((hotelTicket) => hotelTicket.id === ticket.id)
                    )
                })
                .flatMap((hotel) => hotel.rooms)
                .filter((room) => {
                    return (
                        room.tickets.length === 0 ||
                        room.tickets.some((roomTicket) => roomTicket.id === ticket.id)
                    )
                })
        },
        [hotels]
    )

    const getTicketsStatus = useCallback(() => {
        const minPrice = minPricesRef.current
        return tickets.reduce<TicketStatusType[]>((statuses, ticket) => {
            if (ticket.hidden) return statuses

            const ticketStatus: TicketStatusType = {
                buttonStatus: cardButtonStatus.SELECT_THIS_PACKAGE,
                price: 0,
                shouldShowPrice: false,
                ticketId: ticket.id
            }

            if (inPreSaleInterval && isPreSale && !isCodeValid) {
                ticketStatus.buttonStatus = cardButtonStatus.UNLOCK
                return [...statuses, ticketStatus]
            }
            const associateRooms = getTicketsAssociateRooms(ticket)
            let minHotelPrice = minPrice.minimumHotelPrice
            if (isOnlySellTickets) {
                minHotelPrice = 0
            } 
            const totalPrice = minPrice?.minimumPackagePrice[ticket.id]?.price
            const ticketPrice = totalPrice - minHotelPrice
            const earliestTicketOnSaleDate = getEarliestOnSaleDate(ticket)
            const isTicketOnFutureSale =
                earliestTicketOnSaleDate && earliestTicketOnSaleDate > new Date()
            let shouldShowPrice = true;
            if (isNaN(totalPrice) || totalPrice < 0) {
                    shouldShowPrice = false
            }
            if (isOnlySellTickets) {
                if (isTicketOnFutureSale && !isPreSale && !isCodeValid) {
                    ticketStatus.buttonStatus = `On Sale ${getTimeZone(
                        earliestTicketOnSaleDate,
                        timezone
                    ).format('MMM D, YYYY @ hh:mm A')} ${timeZoneString(timezone)}`
                } else if (!minPrice.minimumPackagePrice[ticket.id]?.isAvailable) {
                    ticketStatus.buttonStatus = cardButtonStatus.SOLD_OUT
                }
                ticketStatus.price = ticketPrice
                ticketStatus.shouldShowPrice = shouldShowPrice
                return [...statuses, ticketStatus]
            }

            const availableRooms = getAvailableRooms(inventory, false, associateRooms, ticket.id)
            //TODO : Need to confirm logic for this
            // const shouldShowPrice = Object.keys(hotelPriceMap).length > 0

            const isAllRoomsSoldFuture = isAllRoomsSoldOnFuture(availableRooms)
            const earliestRoomOnSaleDate = getEarliestRoomOnSaleDate(availableRooms)
            const earliestOnSaleDate =
                earliestRoomOnSaleDate && earliestTicketOnSaleDate
                    ? earliestTicketOnSaleDate > earliestRoomOnSaleDate
                        ? earliestTicketOnSaleDate
                        : earliestRoomOnSaleDate
                    : earliestTicketOnSaleDate || earliestRoomOnSaleDate

            if ((isAllRoomsSoldFuture || isTicketOnFutureSale) && !isPreSale && !isCodeValid) {
                ticketStatus.buttonStatus = `On Sale ${getTimeZone(
                    earliestOnSaleDate!,
                    timezone
                ).format('MMM D, YYYY @ hh:mm A')} ${timeZoneString(timezone)}`
            } else if ( !minPrice.minimumPackagePrice[ticket.id]?.isAvailable ) {
                ticketStatus.buttonStatus = cardButtonStatus.SOLD_OUT
            }
            ticketStatus.price = totalPrice
            ticketStatus.shouldShowPrice = shouldShowPrice

            return [...statuses, ticketStatus]
        }, [])
    }, [
        tickets,
        inPreSaleInterval,
        isPreSale,
        isCodeValid,
        // getTicketsAssociateRooms,
        // getTicketPrice,
        // getHotelPrice,
        timezone,
        isOnlySellTickets,
        inventory
    ])

 
    useEffect(() => {
        if (!isOnlySellHotel && minPricesRef.current !==null && tickets) {
            setTicketsStatus(getTicketsStatus())
        }
    }, [
        event,
        isCodeValid,
        isOnlySellHotel,
        isOnlySellTickets,
        isPreSale,
        inPreSaleInterval,
        inventory
        // getTicketsStatus
    ])

    return ticketsStatus
}
