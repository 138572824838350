import { Dispatch } from 'redux'
import { CheckoutInsuranceDetailsParams, OrderState, SplitOrderState } from '../index'
import { refresh } from '../../util/refresh'
import { Address } from './fetchCart'
import getConfig from 'next/config'
// import {toaster} from "evergreen-ui";
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

export interface TransactionDetailsParams {
    // Stripe
    stripeCustomerId: string
    stripePaymentIntentId: string
    stripePaymentMethodId: string

    // Braintree
    braintreeCustomerId: string
    braintreePaymentMethodId: string
    braintreeTransactionId: string
    shouldVaultPayment: boolean
    usage: 'MULTI_USE' | 'SINGLE_USE'
}
export interface PayInstallmentJSON {
    amount: number
    shippingInfo: Address
    billingInfo: Address
    useFuture: boolean
    transactionDetails: TransactionDetailsParams
    payFor?: string
    payForMemberEmail: string
}

export interface AcceptSplitPayInviteParamsJSON {
    amount: number
    billingInfo: any
    description?: string
    insuranceDetails: CheckoutInsuranceDetailsParams | null
    layaway: boolean
    paidForBy?: string
    shippingInfo: any
    transactionDetails: TransactionDetailsParams
    userId?: string
}

const jwt = (headers: Record<string, string>): Record<string, string> => {
    let auth = '{}'
    if (typeof localStorage !== 'undefined') {
        auth = localStorage.getItem('auth') || auth
    }
    const jwt = JSON.parse(auth || '{}').jwt
    // return the headers to the context so httpLink can read them
    return {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : ''
    }
}

const post = async <T>(
    baseUrl: string,
    url: string,
    payload: Record<string, any> | Record<string, any>[]
): Promise<T> => {
    url = `${baseUrl.endsWith('/') ? baseUrl.substr(0, baseUrl.length - 1) : baseUrl}/${
        url.startsWith('/') ? url.substr(1) : url
    }`
    const options = {
        method: 'POST',
        mode: 'cors' as RequestMode,
        headers: jwt({
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }),
        body: JSON.stringify(payload)
    }

    const response = await fetch(url, options)
    return await response.json()
}

export const get = async <T>(baseUrl: string, url: string): Promise<T> => {
    let fetchUrl = `${baseUrl.endsWith('/') ? baseUrl.substr(0, baseUrl.length - 1) : baseUrl}/${
        url.startsWith('/') ? url.substr(1) : url
    }`
    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: jwt({
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        })
    }

    const response = await fetch(fetchUrl, options)
    const jsonData = await response.json()
    if (!response.ok) {
        if (jsonData.message.includes('expired jwt')) {
            try {
                await refresh()
                return get(baseUrl, url)
            } catch (error) {
                window.location.href == '/logout'
            }
        }
        // throw new Error();
    }
    return jsonData
}

export function getEventInventory(ordersUrl: string, eventID: string, groupSize: number) {
    return async function (dispatch: Dispatch) {
        dispatch({ type: 'orders/loadInventory/start' })
        try {
            const response = await get<Record<string, number>>(
                ordersUrl,
                `/inventory/${eventID}?groupSize=${groupSize}&onSale=true`
            )
            if (Object.keys(response).length > 0) {
                dispatch({ type: 'orders/loadInventory/success', payload: response })
            }

            return response
        } catch (e: any) {
            dispatch({ type: 'orders/loadInventory/error', payload: e })
        }
    }
}

export async function getSplitPayOrder(
    ordersUrl: string,
    orderID: string,
    memberID: string,
    customerID?: string,
    paymentMethodID?: string
) {
    const urlSearchParams = new URLSearchParams()
    if (customerID) {
        urlSearchParams.set('customerID', customerID)
    }
    if (paymentMethodID) {
        urlSearchParams.set('paymentMethodID', paymentMethodID)
    }
    const response = await get<SplitOrderState>(
        ordersUrl,
        `/order/${orderID}/invite/${memberID}${
            customerID || paymentMethodID ? '?' + urlSearchParams.toString() : ''
        }`
    )
    return response
}

export function getOrder(ordersUrl: string, orderID: string) {
    return async function (dispatch: Dispatch) {
        dispatch({ type: 'order/load/start' })
        try {
            const response = await get<Record<string, any>>(ordersUrl, `/order/${orderID}`)
            if (!response.error) {
                dispatch({ type: 'order/load/success', payload: response })
            } else {
                dispatch({ type: 'order/load/error', payload: response })
            }
        } catch (e: any) {
            dispatch({ type: 'order/load/error', payload: e })
        }
    }
}

export async function acceptSplitPayInvite(
    ordersUrl: string,
    orderID: string,
    memberID: string,
    params: AcceptSplitPayInviteParamsJSON
) {
    if (typeof params.shippingInfo?.phoneAuthorization !== 'undefined') {
        if (params.shippingInfo.phoneAuthorization == 'true') {
            params.shippingInfo.phoneAuthorization = true
            params.billingInfo.phoneAuthorization = true
        } else {
            params.shippingInfo.phoneAuthorization = false
            params.billingInfo.phoneAuthorization = false
        }
    }

    const response = await post<Record<string, any>>(
        ordersUrl,
        `/v2/order/${orderID}/accept-invite/${memberID}`,
        params
    )
    if ((response as any).message) {
        if ((response as any).message.includes('requires_action')) {
            return response
        }
        throw response
    }
    // handel response on the page
    return response
}

export async function payInstallment(ordersUrl: string, url: string, params: PayInstallmentJSON) {
    console.log({ params })

    // @ts-ignore
    if (params.shippingInfo.phoneAuthorization == 'true') {
        // @ts-ignore
        params.shippingInfo.phoneAuthorization = true
        // @ts-ignore
        params.billingInfo.phoneAuthorization = true
    } else {
        // @ts-ignore
        params.shippingInfo.phoneAuthorization = false
        // @ts-ignore
        params.billingInfo.phoneAuthorization = false
    }

    url = `${ordersUrl.endsWith('/') ? ordersUrl.substr(0, ordersUrl.length - 1) : ordersUrl}/${
        url.startsWith('/') ? url.substr(1) : url
    }`
    const options = {
        method: 'POST',
        mode: 'cors' as RequestMode,
        headers: jwt({
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }),
        body: JSON.stringify(params)
    }

    const response = await (await fetch(url, options)).json()

    if (response.message) {
        throw response
    }
    return response
}

export async function updatePaymentMethods(url: string, params: any) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    }
    const response = await fetch(url, options)
    if (response.ok) {
        return response
    } else {
        const errorResponse = await (await fetch(url, options)).json()
        if (errorResponse.message) {
            throw errorResponse
        }
        return errorResponse
    }
}

export async function getInventory(id: string, groupSize: number, onPostPurchase?: boolean) {
    const isOnPostPurchase = onPostPurchase ?? false
    const cartId = window.localStorage.getItem('shoppingCartID') ?? false
    let path = `/inventory/${id}?groupSize=${groupSize}&onSale=true&onPostPurchase=${isOnPostPurchase}&calculate=true`
    if (cartId) {
        path = `${path}&cartID=${cartId}`
    } 
    return await get<Record<string, number>>(
        publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
        path
    )
}

export async function getInventoryItems(id: string, groupSize: number, onPostPurchase?: boolean) {
    const isOnPostPurchase = onPostPurchase ?? false
    console.log('getInventoryItems', id, groupSize, isOnPostPurchase)

    return await get<Record<string, number>>(
        publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
        `/inventoryItems/${id}?groupSize=${groupSize}&onSale=true&onPostPurchase=${isOnPostPurchase}`
    )
}

export function loadOrders(ordersUrl: string, page = 1, size = 20) {
    return async function (dispatch: Dispatch) {
        dispatch({ type: 'orders/load/start' })
        try {
            const response = await get<OrderState[]>(ordersUrl, `/me/?size=${size}&page=${page}`)
            if (Array.isArray(response)) {
                dispatch({ type: 'orders/load/success', payload: response })
            } else {
                dispatch({ type: 'orders/load/error', payload: response })
            }
            return response
        } catch (e: any) {}
    }
}

export const verifyEmail = async (ordersUrl: string, orderNumber: number, email: string) => {
    return await fetch(ordersUrl + `/orders/${orderNumber}/client-secret?email=${email}`)
}

export async function getFilterOrder(id:string, groupSize:number, onPostPurchase?:boolean, queryParams?:any){
    const isOnPostPurchase = onPostPurchase ?? false
    let path = `/filter/${id}?groupSize=${groupSize}&onSale=true&onPostPurchase=${isOnPostPurchase}&calculate=true`
    const cartId = window.localStorage.getItem('shoppingCartID') ?? false
    if (cartId) {
        path = `${path}&cartID=${cartId}`
    } 
    if (queryParams) {
        path = `${path}&${queryParams}`
    }
    return await get<Record<string, number>>(
        publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
        path
    )
}