import { useCallback, useEffect, useMemo, useState } from 'react'
import { EventJSON } from '../../../events-service/src/models/event'
import { getTimeZone, timeZoneString } from 'util/dates'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { cardButtonStatus } from 'enums'
import { ShoppingCartItem } from 'store'
import { getAvailableRooms, getEarliestRoomOnSaleDate, isAllRoomsSoldOnFuture } from 'util/event'

const DEFAULT_GROUP_SIZE = 2

export const defaultHotelState = {
    buttonStatus: '',
    hotelId: '',
    price: 0,
    saleStatusOnHotelCard: '',
    shouldShowButton: true,
    shouldShowPrice: true,
    packageMinPriceRef:null,
    ticketId:''
}

export type HotelStatusType = typeof defaultHotelState
type HotelStatusReturnType = HotelStatusType[]

type UseHotelStatusType = {
    event: EventJSON
    groupSize?: number
    inPreSaleInterval?: boolean
    inventory: Record<string, number>
    isCodeValid?: boolean
    isOnlySellHotel: boolean
    isPreSale?: boolean
    shouldGetHotelsStatus?: boolean
    ticketExperienceItems?: ShoppingCartItem[]
    ticketItems?: ShoppingCartItem[],
    packageMinPriceRef: any,
    ticketId: string | undefined
}

export const useHotelStatus = ({
    event,
    groupSize = DEFAULT_GROUP_SIZE,
    inPreSaleInterval = false,
    inventory,
    isCodeValid = false,
    isOnlySellHotel,
    isPreSale = false,
    shouldGetHotelsStatus = true,
    ticketExperienceItems = [],
    ticketItems = [],
    packageMinPriceRef,
    ticketId
}: UseHotelStatusType): HotelStatusReturnType => {
    const [hotelsStatus, setHotelsStatus] = useState<HotelStatusType[]>([])
    const { hotels, timezone } = event

    const selectedTicketNumber = ticketItems[0]?.parent || ''

    const shippingFee = useMemo(
        () =>
            event.addOnItems.reduce(
                (total, addOn) =>
                    addOn.required ? total + addOn.price + addOn.taxesAndFees : total,
                0
            ),
        [event.addOnItems]
    )

    const calculateHotelPrice = (groupSize: number, hotel: HotelJSON, ticketId: string) => {
            const hotelStatusObj = packageMinPriceRef.current.minimumPerHotelAndGroupSize[hotel.id][ticketId][groupSize-1]
            return {
                priceData: hotelStatusObj.price,//priceData.price,
                priceDataError: hotelStatusObj.isAvailable? '':'Sold Out',//priceData.error,
                priceDataExcludingInventory: hotelStatusObj.price ? hotelStatusObj.price : 0//priceDataExcludingInventory.price
            }
        }
    const getHotelsStatus = useCallback(
        (groupSize: number, ticketId: string) => {
            return hotels.map((hotel) => {
                if (inPreSaleInterval && isPreSale && !isCodeValid) {
                    return {
                        ...defaultHotelState,
                        hotelId: hotel.id,
                        buttonStatus: cardButtonStatus.UNLOCK,
                        saleStatusOnHotelCard: cardButtonStatus.UNLOCK,
                        shouldShowPrice: false
                    }
                }
                const availableRooms = getAvailableRooms(
                    inventory,
                    false,
                    hotel.rooms,
                    selectedTicketNumber
                )

                const isAllRoomNotOnSale = hotel.rooms.every(
                    (room) => !room.onSale || room.tiers.every((tier) => !tier.onSale)
                )
                if (isAllRoomNotOnSale) {
                    return {
                        ...defaultHotelState,
                        hotelId: hotel.id,
                        buttonStatus: cardButtonStatus.SOLD_OUT,
                        saleStatusOnHotelCard: cardButtonStatus.SOLD_OUT,
                        shouldShowPrice: false
                    }
                }

                const { priceData, priceDataError, priceDataExcludingInventory } =
                    calculateHotelPrice(groupSize, hotel, ticketId)
                const totalPrice = (priceDataExcludingInventory ) / groupSize / 100
                if (isAllRoomsSoldOnFuture(availableRooms) && !isPreSale && !isCodeValid) {
                    const earliestRoomOnSaleDate = getEarliestRoomOnSaleDate(availableRooms)
                    return {
                        ...defaultHotelState,
                        hotelId: hotel.id,
                        saleStatusOnHotelCard: `On Sale ${getTimeZone(
                            earliestRoomOnSaleDate,
                            timezone
                        ).format('MM/DD/YY @ hh:mm A')} ${timeZoneString(timezone)}`,
                        shouldShowButton: false
                    }
                }

                const buttonStatus =
                    priceDataExcludingInventory === 99999999
                        ? cardButtonStatus.SOLD_OUT
                        : priceData === 99999997
                        ? cardButtonStatus.UNAVAILABLE
                        : priceDataError || cardButtonStatus.SELECT_HOTEL

                return {
                    hotelId: hotel.id,
                    price: totalPrice,
                    buttonStatus,
                    saleStatusOnHotelCard: buttonStatus,
                    shouldShowPrice: priceDataExcludingInventory !== 99999999,
                    shouldShowButton: priceData !== 1000000000
                }
            })
        },
        [
            hotels,
            inPreSaleInterval,
            inventory,
            isCodeValid,
            isPreSale,
            selectedTicketNumber,
            shippingFee,
            timezone
        ]
    )

    useEffect(() => {
        if (!shouldGetHotelsStatus) return
        if (packageMinPriceRef.current && ticketId) {
            setHotelsStatus(getHotelsStatus(groupSize, ticketId))
        }
    }, [inventory])

    return hotelsStatus
}
